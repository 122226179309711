import { FC } from 'react';
import { createPortal } from 'react-dom';

import { SideSheetProvider } from './SideSheetContext';
import SideSheetAlertBanner from './components/SideSheetAlertBanner';
import SideSheetBackdrop from './components/SideSheetBackdrop';
import SideSheetContainer from './components/SideSheetContainer';
import SideSheetContent from './components/SideSheetContent';
import SideSheetFooter from './components/SideSheetFooter';
import SideSheetHeader from './components/SideSheetHeader';

const SideSheet: FC<{
  children: React.ReactNode | ((onClose: () => void) => React.ReactNode);
  onClose: () => void;
  shouldBlockClose?: () => boolean;
  // Checking function that return boolean
  // Whenever onClose is called, it will check the function and if it returns true, it will not call onClose
}> & {
  AlertBanner: typeof SideSheetAlertBanner;
  Container: typeof SideSheetContainer;
  Content: typeof SideSheetContent;
  Footer: typeof SideSheetFooter;
  Header: typeof SideSheetHeader;
} = ({ children, onClose, shouldBlockClose }) => {
  if (typeof window === 'undefined') return null;
  return createPortal(
    <SideSheetProvider
      onClose={onClose}
      shouldBlockClose={shouldBlockClose}>
      {(handleClose) => (
        <>
          <SideSheetBackdrop />
          {typeof children === 'function'
            ? children(handleClose)
            : children}
        </>
      )}
    </SideSheetProvider>,
    document.body
  );
};

SideSheet.AlertBanner = SideSheetAlertBanner;
SideSheet.Container = SideSheetContainer;
SideSheet.Content = SideSheetContent;
SideSheet.Footer = SideSheetFooter;
SideSheet.Header = SideSheetHeader;

export default SideSheet;
