import React, { useEffect, useState } from 'react';

import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import PopOverModal from '@/components/common/PopOverModal';
import { showToast } from '@/components/common/ToastContainer';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import {
  CHECKPOINT_ACTION_TYPE,
  CHECKPOINT_SUBMISSION_STATUS_TYPES,
  SUBMISSION_FORM_STATES
} from '@/pages/portal/challenges/constants';
import ConfirmationModal from '@/pages/portal/events/components/ConfirmationModal';

import ParticipantThumbnailStack from '../ParticipantThumbnailStack';

function CheckpointPageFooter({
  completedProfilePics,
  completedCount,
  completionStatus,
  submissionRequired,
  handleCompleteCheckpoint,
  showSuccessScreen,
  openSubmissionForm,
  isSubmissionFormOpen,
  setSubmissionFormState,
  submissionFormState,
  initializeFormData,
  isUpdatingSubmission,
  isCheckpointCompleted,
  isLeaderboardEnabled,
  checkForChangesInQuestions,
  isUploading,
  hasCheckpointStarted,
  isParticipant,
  isPreview,
  canSubmitAfterDeadline,
  hasChallengeEnded,
  checkpointId,
  isCheckpointLocked
}) {
  isLeaderboardEnabled = true;
  const [showProceedToEditWarning, setShowProceedToEditWarning] =
    useState(false);

  // reset state on checkpoint change
  useEffect(() => {
    setShowProceedToEditWarning(false);
  }, [checkpointId]);

  const { isGtEqMd } = useWindowWidthContext();

  if (!hasCheckpointStarted) return null;

  const isModalOpen = showProceedToEditWarning && isGtEqMd;
  const isPopOverOpen = showProceedToEditWarning && !isGtEqMd;

  const handleCompleteSubmission = async () => {
    const { error } =
      (await handleCompleteCheckpoint(CHECKPOINT_ACTION_TYPE.COMPLETE)) ||
      {};

    if (!error) {
      showSuccessScreen();
      return;
    }
  };

  const handleUpdateSubmission = async () => {
    const { error } = await handleCompleteCheckpoint(
      CHECKPOINT_ACTION_TYPE.EDIT
    );
    if (!error) {
      setSubmissionFormState(SUBMISSION_FORM_STATES.VIEWING);
      showToast({ text: t('submission-edited') });
      return;
    }
  };

  const handleProceedToEdit = () => {
    setShowProceedToEditWarning(false);
    checkForChangesInQuestions();
    setSubmissionFormState(SUBMISSION_FORM_STATES.EDITING_SUBMISSION);
  };

  const handleClickEditSubmission = () => {
    // warn user if leaderboard is enabled
    if (isLeaderboardEnabled) {
      setShowProceedToEditWarning(true);
      return;
    }
    handleProceedToEdit();
  };

  const cannotEditOrCompleteCheckpoint =
    hasChallengeEnded && !canSubmitAfterDeadline;

  const renderCheckpointCTA = () => {
    if (isCheckpointLocked) {
      return <></>;
    }
    // if checkpoint has not started, no cta needed
    if (submissionFormState === SUBMISSION_FORM_STATES.VIEWING) {
      return (
        <NPLButton
          hierarchy="outline"
          leadIcon="pencil-01"
          buttonText={t('edit-submission')}
          disabled={cannotEditOrCompleteCheckpoint}
          onClick={handleClickEditSubmission}
          stretch
        />
      );
    }
    if (isCheckpointCompleted && submissionRequired) {
      return (
        <NPLButton
          hierarchy="outline"
          buttonText={t('view-submission')}
          onClick={() => {
            openSubmissionForm(SUBMISSION_FORM_STATES.VIEWING);
          }}
          stretch
        />
      );
    }
    if (
      (submissionRequired &&
        !isSubmissionFormOpen &&
        hasCheckpointStarted) ||
      isPreview
    ) {
      const isDraftStatus =
        completionStatus === CHECKPOINT_SUBMISSION_STATUS_TYPES.DRAFT;

      const getButtonText = () => {
        if (isDraftStatus) {
          return t('continue-submission');
        }
        if (hasCheckpointStarted) {
          return t('make-submission');
        }
        return t('view-submission-form');
      };

      return (
        <NPLButton
          hierarchy="neutral_primary"
          buttonText={getButtonText()}
          onClick={() => {
            const submissionFormState = isDraftStatus
              ? SUBMISSION_FORM_STATES.EDITING_DRAFT
              : SUBMISSION_FORM_STATES.COMPLETING;
            openSubmissionForm(submissionFormState);
          }}
          disabled={isUploading || isPreview}
          stretch
        />
      );
    }
    // If challenge has ended, check for canSubmitAfterDeadline,
    // if true, show button to complete submission
    if (cannotEditOrCompleteCheckpoint) {
      return (
        <NPLButton
          hierarchy="neutral_primary"
          buttonText={t('submission-closed')}
          loading={isUpdatingSubmission}
          onClick={handleCompleteSubmission}
          stretch
          disabled={true}
        />
      );
    }
    if ((!isCheckpointCompleted && hasCheckpointStarted) || isPreview) {
      return (
        <NPLButton
          leadIcon="check"
          hierarchy="success_secondary"
          buttonText={t('mark-as-completed')}
          loading={isUpdatingSubmission}
          onClick={handleCompleteSubmission}
          stretch
          disabled={isUploading || !isParticipant}
        />
      );
    }
    return <></>;
  };

  // Modal texts
  const modalTitle = t('edit-submission');
  const modalSubtitle = t(
    'resubmitting-the-form-will-result-in-a-recalculation-of-your-points-which-may-result-in-receiving-less-points'
  );
  const continueToEditText = t('continue-to-edit');
  const cancelText = t('cancel');

  const renderProceedToEditPopover = () => {
    return (
      <div className="flex flex-col items-center gap-16 px-16 pb-32 text-center font-poppins">
        <Icon name="info-circle" width="32" height="32" fill="#C8C7C1" />
        <h3 className="text-20 font-semibold text-npl-text-icon-on-light-surface-primary">
          {modalTitle}
        </h3>
        <div className="flex w-full flex-col gap-12">
          <NPLButton
            hierarchy="accent_primary"
            buttonText={continueToEditText}
            size="lg"
            onClick={handleProceedToEdit}
          />
          <NPLButton
            hierarchy="outline"
            buttonText={cancelText}
            size="lg"
            onClick={() => setShowProceedToEditWarning(false)}
          />
        </div>
        <p className="pb-24 text-16">{modalSubtitle}</p>
      </div>
    );
  };

  return (
    <div className="bg-white-default flex w-full border-t-1 border-npl-separator-alpha justify-center px-24 pb-32 pt-16 md:pb-16">
      <div className="flex w-full max-w-[560px] flex-col-reverse items-center justify-center gap-24 md:flex-row">
        {submissionFormState ===
        SUBMISSION_FORM_STATES.EDITING_SUBMISSION ? (
          <div className="flex w-full gap-16">
            <NPLButton
              hierarchy="outline"
              buttonText={t('cancel')}
              onClick={() => {
                // Reset all form states
                initializeFormData();
                setSubmissionFormState(SUBMISSION_FORM_STATES.VIEWING);
              }}
              disabled={isUploading}
              stretch
            />
            <NPLButton
              hierarchy="accent_primary"
              buttonText={t('save')}
              loading={isUpdatingSubmission}
              onClick={handleUpdateSubmission}
              disabled={isUploading}
              stretch
            />
          </div>
        ) : (
          <>
            {completedCount > 0 && (
              <div className="flex items-center gap-8">
                <ParticipantThumbnailStack
                  participants={completedProfilePics}
                  showYou={false}
                  size={20}
                />
                <div className="whitespace-nowrap text-label-sm lowercase">
                  {t('participant-count-completed', {
                    participantCount: completedCount
                  })}
                </div>
              </div>
            )}

            {/* {(isParticipant || isPreview) && renderCheckpointCTA()} */}
            {renderCheckpointCTA()}
          </>
        )}
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          title={modalTitle}
          subtitle={modalSubtitle}
          primaryBtnText={cancelText}
          secondaryBtnText={continueToEditText}
          secondaryBtnOnClick={handleProceedToEdit}
          primaryBtnOnClick={() => setShowProceedToEditWarning(false)}
          onClose={() => setShowProceedToEditWarning(false)}
        />
      )}
      {isPopOverOpen && (
        <PopOverModal
          open={isPopOverOpen}
          showCloseIcon={false}
          onClose={() => setShowProceedToEditWarning(false)}>
          {renderProceedToEditPopover()}
        </PopOverModal>
      )}
    </div>
  );
}

export default withComponentClassName(CheckpointPageFooter);
