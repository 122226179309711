import { LazyMotion, domMax, m } from 'framer-motion';
import { FC } from 'react';

import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import { useSideSheet } from '../SideSheetContext';

const SideSheetContainer: FC<
  WithComponentClassName<{
    children: React.ReactNode;
    maxWidth: number;
  }>
> = ({ children, maxWidth = 560, componentClassName }) => {
  const { isClosed, onClose } = useSideSheet();

  return (
    <div className={componentClassName}>
      <LazyMotion features={domMax} strict>
        <m.div
          initial={{ x: maxWidth }}
          animate={{ x: isClosed ? maxWidth : 0 }}
          onAnimationComplete={(definition: { x: number }) => {
            if (isClosed && definition.x === maxWidth) {
              onClose?.();
            }
          }}
          transition={{
            type: 'tween',
            duration: 0.3,
            ease: 'easeInOut'
          }}
          className="fixed z-over-intercom inset-y-0 right-0 h-[100dvh] min-h-0 overflow-y-auto w-full 
          bg-white-default border-l-1 border-npl-neutral-light-solid-6"
          role="dialog"
          aria-modal="true"
          style={{ maxWidth: `${maxWidth}px` }}>
          <div className="relative flex flex-col min-h-0 h-full">
            {children}
          </div>
        </m.div>
      </LazyMotion>
    </div>
  );
};

export default withComponentClassName(SideSheetContainer, false);
