import { FC } from 'react';

const SideSheetContent: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <div className="c-SideSheetContent flex flex-col flex-1 overflow-y-auto">
      {children}
    </div>
  );
};

export default SideSheetContent;
