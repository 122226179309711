import classNames from 'classnames';
import React, { FC, useState } from 'react';

import { t } from '@/utility/localization';
import { formatFileSize } from '@/utility/stringHelper';

import {
  FILE_ITEM_STATUS_ERROR, // FILE_ITEM_STATUS_ERROR,
  STATUS_VIDEO_PROCESSING
} from '@/contexts/LibrarySetupContext/constants';

import NextImage from '@/components/common/NextImage';
import Icon, { IconName } from '@/components/npl/Icon';
import NPLIconButton from '@/components/npl/NPLIconButton';

import { Attachment } from '@/hooks/attachments/type';

import ConfirmationModal from '@/pages/portal/events/components/ConfirmationModal';
import {
  STATUS_PUBLISHED,
  THUMBNAIL
} from '@/pages/portal/products/constants';
import { getFileFormat } from '@/pages/portal/products/utils';

import { UploadFailed } from './AttachmentStates/UploadFailed';
import { VideoProcessing } from './AttachmentStates/VideoProcessing';
import { VideoUploading } from './AttachmentStates/VideoUploading';

type Props = {
  attachment: Attachment;
  handleDeleteAttachment?: () => void;
  isMemberView?: boolean;
  isNotLastItem?: boolean;
  onClick?: () => void;
  setSelectedAttachmentId?: (attachmentId: string) => void;
  uploadInProgress?: boolean;
};

const AttachmentRow: FC<Props> = ({
  attachment,
  setSelectedAttachmentId,
  handleDeleteAttachment,
  uploadInProgress,
  isMemberView,
  onClick,
  isNotLastItem
}) => {
  const {
    _id: attachmentId,
    title,
    type: fileType,
    size: fileSize,
    status,
    thumbnail,
    format: fileFormat,
    link,
    processingPercentComplete,
    metadataList
  } = attachment;

  const roundedProcessingPercentComplete = Math.floor(
    Number(processingPercentComplete)
  );

  const [deleteAttachmentModal, setDeleteAttachmentModal] =
    useState(false);

  const isDefaultThumbnail = thumbnail === THUMBNAIL?.[fileType];
  const isLinkType = fileType === 'external_link';

  const getIconBasedOnFileType = (): IconName => {
    switch (fileType) {
      case 'image':
        return 'image-01';
      case 'video':
        return 'video-recorder';
      case 'external_link':
        return 'link-01';
      default:
        return 'file-01';
    }
  };

  const modalTitle = isLinkType
    ? t('confirm-delete-link')
    : t('confirm-delete-file');

  const modalSubtitle = isLinkType
    ? t('are-you-sure-you-want-to-delete-this-link')
    : t('are-you-sure-you-want-to-delete-this-file');

  const isPublished = status === STATUS_PUBLISHED;
  const isProcessing = status === STATUS_VIDEO_PROCESSING;

  const renderStatus = () => {
    switch (attachment?.status) {
      case STATUS_VIDEO_PROCESSING:
        if (roundedProcessingPercentComplete) {
          return (
            <VideoUploading
              roundedProcessingPercentComplete={
                roundedProcessingPercentComplete
              }
              onDelete={handleDeleteAttachment}
            />
          );
        } else {
          return (
            <VideoProcessing
              processingPercentComplete={
                attachment.processingPercentComplete
              }
              onDelete={handleDeleteAttachment}
            />
          );
        }
      case FILE_ITEM_STATUS_ERROR:
        return <UploadFailed onDelete={handleDeleteAttachment} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames('group flex items-center', {
        'cursor-pointer': !!onClick
      })}
      onClick={onClick}>
      {!isDefaultThumbnail ? (
        <div className="w-64 h-64 flex-shrink-0 relative">
          <NextImage
            mobileImgProps={{
              src: thumbnail,
              layout: 'fill',
              objectFit: 'cover',
              objectPosition: 'center'
            }}
            className="rounded-12"
          />
        </div>
      ) : (
        <div className="w-64 flex-shrink-0 h-64 flex justify-center rounded-12 items-center border-[0.5px] border-npl-transparent-black-5 bg-npl-neutral-light-solid-2">
          <Icon
            name={getIconBasedOnFileType()}
            width={24}
            height={24}
            fill="#1B1B1859"
          />
        </div>
      )}
      <div
        className={classNames(
          'flex w-full items-center h-96 justify-between ml-16 truncate',
          { 'border-b-1 border-npl-separator-alpha': isNotLastItem }
        )}>
        <div className="flex-1 flex-grow truncate">
          {isProcessing && !isMemberView ? (
            <div className="flex w-full flex-grow gap-16 justify-between">
              {renderStatus()}
            </div>
          ) : (
            <div className="flex flex-col gap-4 truncate">
              <p className="text-body-sm break-words font-medium text-npl-text-primary-on-light line-clamp-2 text-wrap truncate">
                {title}
              </p>
              <p
                className={`text-label-sm truncate ${
                  !isLinkType ? 'uppercase' : ''
                } text-npl-text-tertiary-on-light`}>
                {isLinkType
                  ? link
                  : `${getFileFormat(fileFormat)} · ${formatFileSize(fileSize / 1000)}`}
              </p>

              {isPublished && (
                <div className="flex w-auto max-w-[640px] flex-row items-center space-x-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {metadataList
                    ?.filter((item) => item !== '' && item)
                    .map((metadata, index, { length }) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row items-center gap-6">
                          {metadata !== '' && (
                            <div className="overflow-hidden overflow-ellipsis text-label-sm text-npl-text-icon-on-light-surface-secondary">
                              {metadata}
                            </div>
                          )}

                          {index !== length - 1 && (
                            <div
                              key={`${index}divider`}
                              className="h-4 w-4 rounded-full bg-npl-text-icon-on-light-surface-secondary"
                            />
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </div>
        {!isMemberView && status === STATUS_PUBLISHED && (
          <div className="ml-8 flex justify-end items-center">
            <div className="gap-4 hidden group-hover:flex transition-all">
              <NPLIconButton
                hierarchy="plain"
                icon="edit"
                size="md"
                onClick={() => {
                  setSelectedAttachmentId(attachmentId);
                }}
              />
              <NPLIconButton
                hierarchy="plain"
                icon="trash-01"
                size="md"
                onClick={() => setDeleteAttachmentModal(true)}
              />
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        title={modalTitle}
        subtitle={modalSubtitle}
        isOpen={deleteAttachmentModal}
        onClose={() => setDeleteAttachmentModal(false)}
        secondaryBtnText={t('delete')}
        primaryBtnText={t('cancel')}
        secondaryBtnOnClick={() => {
          handleDeleteAttachment();
          setDeleteAttachmentModal(false);
        }}
        secondaryBtnHierarchy="destructive"
        primaryBtnOnClick={() => setDeleteAttachmentModal(false)}
        secondaryBtnLoading={uploadInProgress}
      />
    </div>
  );
};

export default AttachmentRow;
