import React, { useState } from 'react';

import { openInNewTab } from '@/utility/helpers';
import { t } from '@/utility/localization';

import Editor from '@/components/common/Editor/Editor';
import { isEditorEmpty } from '@/components/common/Editor/utils/common';
import BorderedRoundedContainer from '@/components/containers/BorderedRoundedContainer';

import ResourcePreviewModal from '@/pages/member/products/components/ResourcePreviewModal/ResourcePreviewModal';
import AttachmentRow from '@/pages/portal/challenges/[challengeId]/components/CheckpointSideDrawer/components/AttachmentsUpload/AttachmentRow';
import CheckpointEventCard from '@/pages/portal/challenges/[challengeId]/components/CheckpointSideDrawer/components/EventUpload/CheckpointEventCard';
import { VideoPreview } from '@/pages/portal/challenges/[challengeId]/components/CheckpointSideDrawer/components/VideoUpload/VideoPreview';

import CheckpointTitle from './PrivateChallengeLayout/components/CheckpointPage/CheckpointTitle';
import CheckpointUnlocksInCountdown from './PrivateChallengeLayout/components/CheckpointPage/CheckpointUnlocksInCountdown';
import NoContentCard from './PrivateChallengeLayout/components/CheckpointPage/NoContentCard';

const ChallengeCheckpointPageCheckpointInfo = ({
  challengeId,
  communityId,
  isPreview,
  checkpointCount,
  selectedCheckpointData,
  hasContent,
  isCheckpointCompleted,
  isLeaderboardEnabled,
  isCheckpointLocked,
  participantJoinedDate,
  isUploading,
  onBackClick,
  infiniteListProps,
  onCheckpointSelect
}) => {
  const [resourceToPreview, setResourceToPreview] = useState(null);

  const { checkpointData } = selectedCheckpointData;

  const { description, coverVideo, event, attachmentItems } =
    checkpointData || selectedCheckpointData || {};

  const renderContent = () => {
    if (isCheckpointLocked) {
      return (
        <CheckpointUnlocksInCountdown
          isPreview={isPreview}
          checkpointData={checkpointData}
          participantJoinedDate={participantJoinedDate}
        />
      );
    }

    if (!hasContent) {
      return <NoContentCard />;
    }

    const videoData = coverVideo?.mediaData?.video;
    const isDefaultThumbnail =
      coverVideo?.mediaData?.thumbnail ===
      coverVideo?.mediaData?.video?.thumbnailLink;

    return (
      <div>
        {videoData && (
          <div className="w-full max-w-[560px] mx-auto">
            <VideoPreview
              video={videoData}
              stretch
              customThumbnail={coverVideo?.mediaData?.thumbnail}
              isDefaultThumbnail={isDefaultThumbnail}
            />
          </div>
        )}
        {event && Object.keys(event).length > 0 && (
          <div className="max-w-[560px] mx-auto">
            <CheckpointEventCard eventData={event} isMemberView />
          </div>
        )}
        <div className="mb-32">
          {!isEditorEmpty(description) && (
            <div className="mt-24 md:p-0">
              <Editor
                existingContent={description}
                editable={false}
                customContentClasses="max-w-[560px] mx-auto w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
                imageIdentity={{}}
                isAutoFocus={false}
                placeholderText=""
              />
            </div>
          )}
          {attachmentItems?.length > 0 && (
            <div className="max-w-[560px] mx-auto mt-24 ">
              <BorderedRoundedContainer className="pb-12">
                <div className="flex justify-between items-center">
                  <div className="text-label-lg font-semibold">
                    {t('attachments')}
                  </div>
                </div>
                {attachmentItems?.map((attachment, index) => (
                  <AttachmentRow
                    key={attachment?._id}
                    attachment={attachment}
                    onClick={() => {
                      if (attachment?.link) {
                        openInNewTab(attachment.link);
                      } else {
                        setResourceToPreview(attachment);
                      }
                    }}
                    isMemberView
                    isNotLastItem={index !== attachmentItems.length - 1}
                  />
                ))}
              </BorderedRoundedContainer>
            </div>
          )}
        </div>
        {resourceToPreview && (
          <ResourcePreviewModal
            resource={resourceToPreview}
            onClose={() => setResourceToPreview(null)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="c-ChallengeCheckpointPageCheckpointInfo flex flex-col gap-24">
      <CheckpointTitle
        challengeId={challengeId}
        communityId={communityId}
        isPreview={isPreview}
        checkpointData={checkpointData}
        selectedCheckpointData={selectedCheckpointData}
        checkpointCount={checkpointCount}
        isCheckpointCompleted={isCheckpointCompleted}
        isUploading={isUploading}
        onBackClick={onBackClick}
        infiniteListProps={infiniteListProps}
        onCheckpointSelect={onCheckpointSelect}
        isLeaderboardEnabled={isLeaderboardEnabled}
      />
      <div className="flex-1 overflow-y-auto">{renderContent()}</div>
    </div>
  );
};

export default ChallengeCheckpointPageCheckpointInfo;
