import React from 'react';

import { trackGAEvent } from '@/utility/analytics';
import { formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import InfiniteList from '@/components/common/InfiniteList';
import NPLPopOverOption from '@/components/common/NPLPopoverMenu/NPLPopOverOption';
import Icon from '@/components/npl/Icon';
import NPLIconButton from '@/components/npl/NPLIconButton';
import NPLModal from '@/components/npl/NPLModalNew';

const CheckpointTitle = ({
  challengeId,
  communityId,
  isPreview,
  checkpointData,
  selectedCheckpointData,
  checkpointCount,
  isCheckpointCompleted,
  isUploading,
  onBackClick,
  infiniteListProps,
  onCheckpointSelect,
  isLeaderboardEnabled
}) => {
  const { isGtEqLg } = useWindowWidthContext();
  const { _id: selectedCheckpointId, title } =
    checkpointData || selectedCheckpointData || {};

  if (isPreview) {
    return (
      <div className="id-isPreview mx-auto mb-24 w-full max-w-[560px] gap-4">
        <div className="max-w-[300px] lg:max-w-[560px] text-label-lg font-semibold">
          <div className="text-label-md text-npl-text-secondary-on-light px-24 md:px-0">
            {t('checkpoint-number-of-total-checkpoints', {
              checkpointNumber: checkpointData?.index,
              totalCheckpoints: checkpointCount
            })}
          </div>
          <div className="flex flex-row items-center gap-4 px-24 md:px-0">
            {checkpointCount && (
              <Icon
                name="check-circle-filled"
                width={16}
                height={16}
                fill="#30A66D"
              />
            )}
            <p className="text-heading-md font-semibold text-dark-1b">
              {title}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const pointsEarned =
    selectedCheckpointData?.leaderboardInfo?.pointsEarned ??
    selectedCheckpointData?.checkpointLeaderboardInfo?.pointsEarned ??
    0;

  const titleEl = (
    <div className="flex items-center gap-4">
      {isCheckpointCompleted && (
        <Icon
          name="check-circle-filled"
          width={16}
          height={16}
          fill="#30A66D"
        />
      )}
      <p className="text-heading-lg font-semibold text-dark-1b">{title}</p>
    </div>
  );

  return (
    <div className="w-full max-w-[560px] mx-auto">
      {/* Back button */}
      <div className="lg:hidden mb-16">
        <NPLIconButton
          size="sm"
          hierarchy="plain"
          icon="chevron-left"
          disabled={isUploading}
          onClick={onBackClick}
        />
      </div>
      <div className="flex flex-col gap-4">
        {isGtEqLg ? (
          titleEl
        ) : (
          <HeadlessDropdown
            placement="bottom"
            renderField={() => (
              <div className="flex gap-8 items-center">
                {titleEl}
                <Icon name="chevron-down" width={16} height={16} />
              </div>
            )}
            renderOptions={({ closeDropdown }) => (
              <NPLModal onClose={closeDropdown}>
                {() => {
                  return (
                    <NPLModal.Container>
                      <>
                        <NPLModal.Header
                          hasCloseIcon={false}
                          title={
                            <div className="flex-1 text-center">
                              <h3 className="text-label-lg font-semibold">
                                {t('checkpoints')}
                              </h3>
                            </div>
                          }
                        />
                        <NPLModal.Content className="!p-0">
                          <div className="px-8 pt-12 pb-24">
                            <InfiniteList {...infiniteListProps}>
                              {(checkpoint, index) => {
                                return (
                                  <NPLPopOverOption
                                    key={checkpoint._id}
                                    option={{
                                      index: index + 1,
                                      id: checkpoint?._id,
                                      title: checkpoint?.title,
                                      icon: 'flag-01',
                                      onClick: () => {
                                        onCheckpointSelect(
                                          checkpoint?._id
                                        );

                                        trackGAEvent(
                                          'member_challenge_checkpoint_mobile_dropdown_item_clicked',
                                          {
                                            productId: challengeId,
                                            productType: 'CHALLENGE',
                                            communityId: communityId
                                          }
                                        );
                                        closeDropdown();
                                      }
                                    }}
                                    isSelected={
                                      checkpoint._id ===
                                      selectedCheckpointId
                                    }
                                  />
                                );
                              }}
                            </InfiniteList>
                          </div>
                        </NPLModal.Content>
                      </>
                    </NPLModal.Container>
                  );
                }}
              </NPLModal>
            )}
          />
        )}

        {isCheckpointCompleted && isLeaderboardEnabled && (
          <div className="text-label-sm lowercase text-npl-text-icon-on-light-surface-secondary">
            {`${formatNumber(pointsEarned)} ${t('points')}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckpointTitle;
