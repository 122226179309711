import dynamic from 'next/dynamic';
import { FC } from 'react';

import type { ShakaPlayerType } from './types';

const ShakaPlayer = dynamic(
  () => import('@/components/common/AdaptiveVideo/partials/ShakaPlayer'),
  {
    ssr: false
  }
);

const NativeVideoPlayer = dynamic(
  () =>
    import('@/components/common/AdaptiveVideo/partials/NativeVideoPlayer'),
  {
    ssr: false
  }
);

type AdaptiveVideoProps = ShakaPlayerType;

const AdaptiveVideo: FC<AdaptiveVideoProps> = (props) => {
  // Support for QuickTime, because app can upload quicktime
  // On web, we convert to mp4 to support streaming but
  // We can't stream mov/qt files
  if (
    props.videoAssets?.mp4 &&
    (props.videoAssets?.mp4?.endsWith('.mov') ||
      props.videoAssets?.mp4?.endsWith('.qt'))
  ) {
    return <NativeVideoPlayer {...props} />;
  }
  return <ShakaPlayer {...props} />;
};

export default AdaptiveVideo;
