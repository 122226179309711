import { ChangeEvent, FC, useState } from 'react';
import { createPortal } from 'react-dom';

import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import Textarea from '@/components/common/Form/Textarea';
import NPLButton from '@/components/npl/NPLButton';
import NPLToggle from '@/components/npl/NPLToggle';

type FeedFormDataType = {
  caption: string;
  includeSubmissionInFeed: boolean;
};

type FeedFormScreenProps = {
  checkpointTitle: string;
  feedFormData: FeedFormDataType;
  onSubmit: () => Promise<void>;
  setFeedFormData: (data: FeedFormDataType) => void;
  submissionRequired: boolean;
};

const FeedFormScreen: FC<FeedFormScreenProps> = ({
  checkpointTitle,
  feedFormData,
  setFeedFormData,
  onSubmit,
  submissionRequired
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return createPortal(
    <div className="fixed left-0 top-0 z-over-intercom m-0 flex h-[100dvh] w-full flex-col bg-white-default">
      <div className="mx-auto min-h-0 w-full flex-1 overflow-auto px-16 py-40 md:p-40">
        <div className="m-auto w-full max-w-[560px]">
          <div className="space-y-4 p-8 text-center">
            <p className="text-label-sm text-npl-text-icon-on-light-surface-secondary">
              {t('posting-to-challenge-feed')}
            </p>
            <p className="text-label-lg font-semibold">
              {checkpointTitle}
            </p>
          </div>
          <div className="space-y-16 py-16">
            <Textarea
              isFullyExtendableByTyping
              placeholder={t('how-was-your-experience')}
              customClassNames="min-h-96 text-body-md px-16 py-12 rounded-8 border-1 bg-white-default shadow-npl-styles-button-shadow border-npl-neutral-light-solid-7 w-full"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setFeedFormData({
                  ...feedFormData,
                  caption: e.target.value
                });
              }}
              value={feedFormData?.caption || ''}
            />
            {submissionRequired && (
              <div className="flex justify-between space-x-16">
                <p className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                  {t('include-my-submission')}
                </p>
                <NPLToggle
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFeedFormData({
                      ...feedFormData,
                      includeSubmissionInFeed: e.target.checked
                    })
                  }
                  checked={feedFormData?.includeSubmissionInFeed}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="z-1 flex w-full items-center justify-center border-t-1 border-npl-neutral-light-solid-4 bg-white-default px-24 py-16">
        <div className="w-300">
          <NPLButton
            size="md"
            hierarchy="neutral_primary"
            buttonText={t('done')}
            onClick={async () => {
              setIsSubmitting(true);
              await onSubmit();
              setIsSubmitting(false);
            }}
            stretch
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default withComponentClassName(FeedFormScreen);
