import { FC } from 'react';

const SideSheetAlertBanner: FC<{
  text: string;
  type: 'neutral' | 'yellow';
}> = ({ type = 'neutral', text = '' }) => {
  return (
    <div
      className={`c-SideSheetAlertBanner flex flex-row items-center h-54 p-16 text-body-sm text-npl-text-icon-on-light-surface-secondary ${
        type === 'neutral'
          ? 'bg-npl-neutral-light-solid-3 text-npl-text-icon-on-light-surface-secondary'
          : 'bg-npl-yellow-light-solid-3 text-npl-text-primary-accent'
      }`}>
      {text}
    </div>
  );
};

export default SideSheetAlertBanner;
