import React from 'react';

import NPLIconButton from '@/components/npl/NPLIconButton';

type Props = {
  challengeTitle: string;
  isPreview: boolean;
  isUploading: boolean;
  onBackClick: () => void;
  showCheckpointSidenav: boolean;
  toggleCheckpointSidenav: () => void;
};

const ChallengeCheckpointPageHeader = ({
  challengeTitle,
  onBackClick,
  toggleCheckpointSidenav,
  isUploading,
  showCheckpointSidenav,
  isPreview
}: Props) => {
  return (
    <div className="c-ChallengeCheckpointPageHeader px-12 flex items-center justify-between h-56 w-full">
      <div className="flex gap-4 items-center">
        <NPLIconButton
          size="sm"
          hierarchy="plain"
          icon="chevron-left"
          disabled={isUploading}
          onClick={onBackClick}
        />

        <h2 className="text-label-lg font-medium text-dark-1b">
          {challengeTitle}
        </h2>
      </div>
      {!isPreview && (
        <NPLIconButton
          key={
            showCheckpointSidenav ? 'layout-right-filled' : 'layout-right'
          } // needed so that the icon can toggle between filled and outline
          hierarchy="plain"
          size="sm"
          onClick={toggleCheckpointSidenav}
          icon={
            showCheckpointSidenav ? 'layout-right-filled' : 'layout-right'
          }
        />
      )}
    </div>
  );
};

export default ChallengeCheckpointPageHeader;
