import classNames from 'classnames';

import { ToggleSizeType } from './types';

export const getSizeClassNames = (size: ToggleSizeType) => {
  switch (size) {
    case 'md':
      return 'min-w-[50px] w-[50px] h-[32px]';
    case 'xs':
      return 'min-w-[33px] w-[33px] h-[18.5px]';
    case 'sm':
    default:
      return 'min-w-[42px] w-[42px] h-[24px]';
  }
};

export const getBoxClassNames = (size: ToggleSizeType) => {
  const baseClassNames = 'w-full h-full rounded-full relative';

  const peerClassNames = classNames(
    'bg-npl-neutral-light-solid-3 hover:bg-npl-neutral-light-solid-4', //off
    'peer-checked:bg-npl-yellow-light-alpha-11 peer-checked:hover:bg-npl-yellow-dark-solid-8', //on
    'peer-disabled:cursor-not-allowed peer-disabled:bg-npl-transparent-black-10 peer-disabled:peer-checked:bg-npl-yellow-dark-alpha-3'
  );

  const boxSizes: Record<ToggleSizeType, string> = {
    xs: 'peer-checked:[&>*:first-child]:translate-x-[calc(100%-1.5px)] p-[1.5px]',
    sm: 'peer-checked:[&>*:first-child]:translate-x-[calc(100%-2px)] p-2',
    md: 'peer-checked:[&>*:first-child]:translate-x-[calc(100%-2px)] py-4 px-2'
  };

  return classNames(baseClassNames, peerClassNames, boxSizes[size]);
};

export const getCircleElementClassNames = (
  size: ToggleSizeType,
  disabled: boolean,
  checked: boolean
) => {
  const baseClasses =
    'absolute transition-all rounded-full drop-shadow-sm';

  const bgColorClass = disabled
    ? checked
      ? 'bg-npl-neutral-light-solid-2'
      : 'bg-npl-neutral-light-solid-3'
    : 'bg-white-default';

  const circleSizes = {
    xs: 'h-[15px] w-[15px]',
    sm: 'h-20 w-20',
    md: 'h-24 w-24'
  };

  return classNames(baseClasses, bgColorClass, circleSizes[size]);
};
