import React from 'react';

import SideSheet from '@/components/npl/SideSheet';

const SideDrawer = ({
  onClose,
  open = true,
  children,
  customCloseIcon = null,
  maxWidth = 560
}) => {
  return (
    <>
      {open && (
        <SideSheet onClose={onClose}>
          {(onClose) => (
            <SideSheet.Container maxWidth={maxWidth}>
              <SideSheet.Content>
                {customCloseIcon ? customCloseIcon(onClose) : null}
                {typeof children === 'function'
                  ? children(onClose)
                  : children}
              </SideSheet.Content>
            </SideSheet.Container>
          )}
        </SideSheet>
      )}
    </>
  );
};

export default SideDrawer;
