import React, { useState } from 'react';

import { copyToClipboard } from '@/utility/helpers';
import { t } from '@/utility/localization';
import { getFileNameWithExtensionFromLink } from '@/utility/stringHelper';
import withComponentClassName from '@/utility/withComponentClassName';

import FileUploaderInput, {
  FILE_UPLOADER_TYPES
} from '@/components/common/ImageUploader/FileUploaderInput';

import VideoSubmissionPreview from '@/pages/portal/challenges/[challengeId]/components/ParticipantsTab/components/ParticipantDetailsSideDrawer/ActivityCard/VideoSubmissionPreview';
import { download } from '@/pages/portal/products/utils';

import { showErrorToast } from '../ToastContainer';
import { showToast } from '../ToastContainer';
import useVideoUploader from './useVideoUploader';
import { getVideoDuration } from './utils';

const DEFAULT_VIDEO_DURATION_SECOND_LIMIT = 90;

function VideoUploader({
  communityId,
  entityId,
  entityType,
  onUploaded,
  onUpload,
  onCancel,
  value,
  videoDurationLimit = DEFAULT_VIDEO_DURATION_SECOND_LIMIT,
  otherBodyParamsForVideoUpload,
  disabled,
  hasThumbnailUpload
}) {
  const { handleUploadVideo, uploadingVideo, videoTranscodingProgress } =
    useVideoUploader({
      communityId,
      entityId,
      entityType
    });
  const [showPreview, setShowPreview] = useState(false);

  const handleSelectingFile = async (e) => {
    const file = e.target.files[0];

    const videoDuration = await getVideoDuration(file);

    if (videoDuration > videoDurationLimit) {
      showErrorToast(
        t(
          'video-duration-should-be-less-than-videodurationlimit-seconds',
          {
            videoDurationLimit
          }
        )
      );
      return;
    }

    const sizeCompressionRequiredLimit = 50 * 1024 * 1024; //50MB

    onUpload?.();
    const { videoLink, fileSize, videoThumbnailLink } =
      await handleUploadVideo({
        file: file,
        isCompressionNeeded:
          file.size > sizeCompressionRequiredLimit ||
          file.type !== 'video/mp4',
        hasThumbnailUpload: hasThumbnailUpload,
        otherBodyParams: { ...otherBodyParamsForVideoUpload }
      });

    if (videoLink) {
      onUploaded({ videoLink, fileSize, videoThumbnailLink });
    }
  };

  // function to open user file dialog
  const openFileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', 'video/*');
    fileSelector.addEventListener('change', handleSelectingFile);
    fileSelector.click();
  };

  const onCopyClick = () => {
    copyToClipboard(value);
    showToast({ text: t('copied') });
  };

  const fileNameWithExtension = getFileNameWithExtensionFromLink(value);

  const onDownloadClick = () => {
    download(t, value, fileNameWithExtension);
  };

  return (
    <>
      <FileUploaderInput
        onClick={() => (value ? setShowPreview(true) : openFileSelector())}
        fileValue={value}
        fileName={fileNameWithExtension}
        onCancel={() => {
          onCancel();
        }}
        emptyStateTitle={t('upload-video')}
        emptyStateDescription={t('1m-30s-limit')}
        disabled={disabled}
        uploading={uploadingVideo}
        transcodingProgress={videoTranscodingProgress}
        fileType={FILE_UPLOADER_TYPES.VIDEO}
      />
      {showPreview && (
        <VideoSubmissionPreview
          videoLink={value}
          onCopyClick={onCopyClick}
          onDownloadClick={onDownloadClick}
          onClose={() => setShowPreview(false)}
        />
      )}
    </>
  );
}

export default withComponentClassName(VideoUploader);
