import { LazyMotion, domMax, m } from 'framer-motion';
import { FC } from 'react';

import { useSideSheet } from '../SideSheetContext';

const SideSheetBackdrop: FC = () => {
  const { handleClose, isClosed } = useSideSheet();
  return (
    <LazyMotion features={domMax} strict>
      <m.div
        onClick={handleClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: isClosed ? 0 : 1 }}
        transition={{
          type: 'tween',
          duration: 0.3,
          ease: 'easeInOut'
        }}
        className="fixed inset-0 bg-npl-transparent-black-50 !z-over-intercom"
      />
    </LazyMotion>
  );
};

export default SideSheetBackdrop;
