import { STATIC_ASSET_BASE_URL } from '@/utility/constants';

export const TRAFFIC_TAB = {
  id: 'traffic',
  key: 'traffic'
};

export const MEMBER_GROWTH_TAB = {
  id: 'member-growth',
  key: 'member-growth'
};

export const tabs = [TRAFFIC_TAB, MEMBER_GROWTH_TAB];

export const DEFAULT_IMG_URI_FOR_USER_ON_CHAT = `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/people-icon-new.png`;
