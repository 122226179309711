import classNames from 'classnames';
import { addDays, isSameYear } from 'date-fns';
import React from 'react';

import { formatDateTimeWithLocale, t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { PARTICIPANT_PROGRAM_ITEM_STATUS } from '@/pages/portal/challenges/constants';

import CheckpointTimingInfo from './CheckpointTimingInfo';

export const CheckpointPreviewCardSkeleton = () => {
  return (
    <div className="flex gap-x-16">
      <div className="flex h-40 w-40 items-center justify-center rounded-8 border bg-npl-neutral-light-solid-3 border-npl-neutral-light-solid-3">
        <Icon
          name="lock-03"
          className="fill-npl-text-tertiary-on-light"
          width={24}
          height={24}
        />
      </div>
      <div>
        <div className="text-label-lg font-medium text-npl-text-icon-on-light-surface-primary">
          <div className="h-[20px] w-[100px] animate-pulse rounded-8 bg-npl-neutral-light-solid-3"></div>
        </div>
        <div>
          <div className="mt-4 h-[16px] w-[200px] animate-pulse rounded-8 bg-npl-neutral-light-solid-3"></div>
        </div>
      </div>
    </div>
  );
};

const CheckpointPreviewCard = ({
  checkpoint,
  hasDivider = false,
  isBlurDivider = false,
  // hasManagerAccess = false,
  completionStatus = null,
  isCheckpointLocked = true,
  isCheckpointCompleted = false,
  isCurrentParticipantCheckpoint = false,
  participantJoinedDate = null,
  onClick = null,
  isPreview = false,
  isInSidebar = false,
  isSelected = false,
  participantId = ''
}) => {
  const { endTime, title, unlockAfterXDays } = checkpoint;
  let { startTime } = checkpoint;

  if (!startTime && !isPreview) {
    startTime = addDays(
      participantJoinedDate ?? new Date(),
      unlockAfterXDays ?? 0
    );
  }

  const isLateCheckpointSubmission =
    completionStatus === PARTICIPANT_PROGRAM_ITEM_STATUS.LATE_COMPLETED;

  const isHappeningNow =
    new Date(startTime) <= new Date() &&
    new Date(endTime) > new Date() &&
    !isCheckpointCompleted;

  // const isClickable =
  //   (!isCheckpointLocked || hasManagerAccess) && Boolean(onClick);
  const isClickable = Boolean(onClick);

  const formattedStartTime = formatDateTimeWithLocale(
    new Date(startTime),
    isSameYear(new Date(), new Date(startTime))
      ? 'dd MMM, hh:mm a'
      : 'dd MMM yyyy, hh:mm a'
  );

  const isSubmittable =
    !isCheckpointLocked && !isCheckpointCompleted && participantId;

  const statusIconSize = isInSidebar ? 20 : 24;

  return (
    <div
      className={classNames({
        'pb-48': isBlurDivider,
        'pb-24': !isBlurDivider
      })}>
      <div
        className={classNames(
          'group p-8 rounded-12 relative flex items-center gap-x-16 hover:bg-npl-neutral-light-alpha-2',
          {
            'cursor-pointer': isClickable,
            'bg-npl-yellow-light-alpha-3': isSelected
            // 'hover:bg-npl-neutral-light-alpha-2': !isSelected
          }
        )}
        onClick={() => {
          if (isClickable) {
            onClick();
          }
        }}>
        <div className="h-full self-start">
          <div
            className={classNames(
              'flex items-center justify-center rounded-8 border',
              {
                'w-32 h-32': isInSidebar,
                'w-40 h-40': !isInSidebar,
                'bg-white-default border-npl-separator-alpha':
                  isSubmittable || (!participantId && !isCheckpointLocked),
                'bg-npl-neutral-light-solid-3 border-npl-neutral-light-solid-3':
                  isCheckpointLocked,
                'bg-npl-success-light-3 border-npl-success-light-6':
                  isCheckpointCompleted && !isCheckpointLocked,
                'bg-white-default border-npl-yellow-light-alpha-6':
                  (isCurrentParticipantCheckpoint && isSubmittable) ||
                  isHappeningNow
              }
            )}>
            {isCheckpointLocked && (
              <Icon
                name="lock-03"
                className="fill-npl-text-tertiary-on-light"
                width={statusIconSize}
                height={statusIconSize}
              />
            )}
            {!isCheckpointLocked && !isCheckpointCompleted && (
              <Icon
                name="check"
                className="fill-npl-text-disabled-on-light"
                width={statusIconSize}
                height={statusIconSize}
              />
            )}
            {isCheckpointCompleted && !isCheckpointLocked && (
              <div className="flex h-full w-full flex-col items-center justify-center">
                <Icon
                  name="check"
                  className="fill-npl-success-light-11"
                  width={statusIconSize}
                  height={statusIconSize}
                />
                {isLateCheckpointSubmission && (
                  <span
                    className={classNames(
                      'mt-[-6px] font-medium text-npl-success-light-11',
                      {
                        'text-label-xs': isInSidebar,
                        'text-label-sm': !isInSidebar
                      }
                    )}>
                    {t('late')}
                  </span>
                )}
              </div>
            )}
          </div>
          {hasDivider && (
            <div
              className={classNames(
                'absolute bottom-0 h-full border-l-2',
                {
                  'top-40 mx-16': isInSidebar,
                  'top-48 mx-20': !isInSidebar,
                  'border-dashed border-npl-neutral-light-solid-6':
                    isCheckpointLocked,
                  'border-npl-success-light-6':
                    isCheckpointCompleted && !isCheckpointLocked,
                  'border-npl-separator-alpha':
                    !isCheckpointLocked && !isCheckpointCompleted
                }
              )}>
              {isBlurDivider && (
                <div className="bg-gradient-to-b h-18 w-18 translate-x-[-10px] translate-y-[100%] from-transparent to-white-default"></div>
              )}
            </div>
          )}
        </div>
        <div>
          <div className="w-full min-h-[40px] flex flex-col justify-center">
            <div
              className={classNames('font-medium', {
                'text-label-md': isInSidebar,
                'text-label-lg': !isInSidebar,
                'text-npl-text-icon-on-light-surface-secondary':
                  isCheckpointLocked,
                'text-npl-text-icon-on-light-surface-primary':
                  !isCheckpointLocked
              })}>
              {title}
            </div>
            <CheckpointTimingInfo
              isHappeningNow={isHappeningNow}
              startTime={startTime}
              isCheckpointLocked={isCheckpointLocked}
              isParticipant={Boolean(participantJoinedDate)}
              isPreview={isPreview}
              formattedStartTime={formattedStartTime}
              checkpoint={checkpoint}
            />
          </div>
        </div>
        {!isInSidebar && isClickable && (
          <div className="ml-auto">
            {isSubmittable ? (
              <NPLButton
                buttonText={t('start')}
                size="sm"
                hierarchy="neutral_primary"
              />
            ) : (
              <NPLButton
                buttonText={t('view')}
                size="sm"
                hierarchy="neutral_secondary"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckpointPreviewCard;
