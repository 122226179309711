import { isFuture, isPast } from 'date-fns';
import { FC } from 'react';

import { t } from '@/utility/localization';

import { CheckpointType } from '../types';

type CheckpointTimingInfoProps = {
  checkpoint: CheckpointType;
  formattedStartTime: string;
  isCheckpointLocked: boolean;
  isHappeningNow: boolean;
  isParticipant: boolean;
  isPreview: boolean;
  startTime: string | Date;
};

export const CheckpointTimingInfo: FC<CheckpointTimingInfoProps> = ({
  isHappeningNow,
  startTime,
  isCheckpointLocked,
  isPreview,
  formattedStartTime,
  checkpoint,
  isParticipant
}) => {
  // Checkpoint is happening now
  if (isHappeningNow) {
    return (
      <p className="mt-4 text-npl-yellow-light-alpha-11 text-label-sm">
        {t('happening-now')}
      </p>
    );
  }

  // Checkpoint has already started but is locked = sequential unlocking is enabled
  if (
    isParticipant &&
    startTime &&
    isCheckpointLocked &&
    isPast(new Date(startTime))
  ) {
    return (
      <p className="mt-4 text-left text-label-md text-npl-text-icon-on-light-surface-secondary">
        {t('complete-all-previous-checkpoints-to-unlock')}
      </p>
    );
  }

  if (startTime) {
    return (
      <p
        suppressHydrationWarning
        className="mt-4 text-left text-label-md text-npl-text-icon-on-light-surface-secondary">
        {!isPreview && isFuture(new Date(startTime))
          ? t('unlocks-at', {
              date: formattedStartTime
            })
          : formattedStartTime}
      </p>
    );
  }

  if (
    Object.prototype.hasOwnProperty.call(checkpoint, 'unlockAfterXDays')
  ) {
    return (
      <p
        suppressHydrationWarning
        className="mt-4 text-left text-label-md text-npl-text-icon-on-light-surface-secondary">
        {checkpoint?.unlockAfterXDays > 0
          ? `${t('unlocks-after')} ${checkpoint?.unlockAfterXDays} ${
              checkpoint?.unlockAfterXDays > 1 ? t('days') : t('day')
            }`
          : t('unlocks-immediately')}
      </p>
    );
  }

  return null;
};

export default CheckpointTimingInfo;
