import React from 'react';

import { t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';

const NoContentCard = () => {
  const renderIconCircle = ({ icon, fill }) => (
    <div className="w-80 h-80 flex items-center justify-center bg-white-default rounded-full">
      <Icon name={icon} width={40} height={40} fill={fill} />
    </div>
  );

  return (
    <div className="c-Checkpoint-NoContentCard w-full max-w-[560px] mx-auto">
      <div className="p-40 rounded-20 bg-npl-neutral-light-solid-2 flex flex-col items-center gap-16">
        <div className="flex gap-8">
          {renderIconCircle({ icon: 'video-recorder', fill: '#A2DEF1' })}
          {renderIconCircle({ icon: 'trophy-01', fill: '#F3B3008F' })}
          {renderIconCircle({ icon: 'sticker-square', fill: '#F3C4E1' })}
        </div>
        <p className="text-heading-sm font-semibold text-dark-1b">
          {t('no-content-added-yet')}
        </p>
      </div>
    </div>
  );
};

export default NoContentCard;
