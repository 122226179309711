import { useState } from 'react';

import { t } from '@/utility/localization';

import AdaptiveVideo from '@/components/common/AdaptiveVideo';
import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import NPLModalNew from '@/components/npl/NPLModalNew';

import OptionMenuV2 from '@/pages/portal/products/components/OptionMenuV2';

export const VideoPreview = ({
  video,
  deleteVideo = undefined,
  stretch,
  customThumbnail = null,
  isDefaultThumbnail = true
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const handleVideoModalClose = () => {
    setIsVideoPlaying(false);
  };

  const widthClassName = stretch ? 'w-full' : `w-[480px]`;

  const videoLink = video?.mp4Link ?? video?.link;

  return (
    <div
      className={`flex flex-row items-center justify-between overflow-hidden bg-npl-neutral-dark-solid-1 rounded-12 ${widthClassName} group relative`}>
      <div
        className="absolute z-[5] flex h-full w-full cursor-pointer items-center justify-center bg-npl-transparent-black-10"
        onClick={() => {
          setIsVideoPlaying(true);
        }}>
        <div className="flex flex-row items-center justify-center rounded-full bg-blur-50 p-24 ">
          <Icon name="play-filled" fill="#FFFFFF" height={32} width={32} />
        </div>
      </div>
      {deleteVideo && (
        <div className="invisible absolute right-12 top-12 z-10 group-hover:visible">
          <div className="flex h-32 w-32 items-center justify-center rounded-full bg-blur-50">
            <OptionMenuV2
              menuIcon={
                <Icon name="dots-vertical" fill="#FFFFFF" height={18} />
              }
              options={[
                {
                  isDanger: true,
                  label: t('delete'),
                  onClick: () => {
                    deleteVideo(video?._id);
                  },
                  renderIcon: () => (
                    <Icon
                      name="trash-01"
                      className="fill-npl-error-light-11"
                      height={16}
                    />
                  )
                }
              ]}></OptionMenuV2>
          </div>
        </div>
      )}

      <div className="relative aspect-video w-full overflow-hidden rounded-12 ">
        {!isDefaultThumbnail ? (
          <NextImage
            alt="video preview"
            mobileImgProps={{
              src: customThumbnail,
              layout: 'fill',
              objectFit: 'cover'
            }}
          />
        ) : (
          <NextImage
            alt="video preview"
            mobileImgProps={{
              src: video?.thumbnail ?? video?.thumbnailLink,
              width: 480,
              height: 270,
              layout: 'responsive',
              objectFit: 'contain'
            }}
          />
        )}
      </div>

      {isVideoPlaying && (
        <NPLModalNew onClose={handleVideoModalClose}>
          {(onClose) => (
            <NPLModalNew.Container size={768}>
              <NPLModalNew.Content className="!p-0">
                <div className="absolute top-12 right-12 z-10">
                  <NPLButton
                    hierarchy="plain"
                    leadIcon="x-close"
                    rounded
                    size="md"
                    isSecondary
                    onClick={onClose}
                  />
                </div>
              </NPLModalNew.Content>
              <AdaptiveVideo
                videoAssets={{
                  mp4: videoLink
                }}
                isTrailerPlaying={isVideoPlaying}
                onVideoEnd={onClose}
              />
            </NPLModalNew.Container>
          )}
        </NPLModalNew>
      )}
    </div>
  );
};
