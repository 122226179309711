import { FC } from 'react';

const SideSheetHeader: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <div className="c-SideSheetHeader bg-white-default border-b-1 border-npl-separator-alpha">
      {children}
    </div>
  );
};

export default SideSheetHeader;
