import React from 'react';

import { getCountdownFormattedString } from '@/utility/stringHelper';

import useCountdown from '@/hooks/useCountdown';

const UnlocksInCountdownText = ({ unlockTime, className = '' }) => {
  const { countdown } = useCountdown(unlockTime);

  const formattedCountdown = getCountdownFormattedString(countdown);
  return (
    <span className={`c-UnlocksInCountdownText ${className}`}>
      {formattedCountdown}
    </span>
  );
};

export default UnlocksInCountdownText;
