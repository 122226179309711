import PropTypes from 'prop-types';
import React from 'react';

import LoadingCircle from '@/components/common/LoadingCircle';
import NPLButton from '@/components/npl/NPLButton';
import NPLModalNew from '@/components/npl/NPLModalNew';

const ConfirmationModal = ({
  title = '',
  subtitle = '',
  isOpen = false,
  primaryBtnText = '',
  secondaryBtnText = '',
  primaryBtnOnClick = null,
  secondaryBtnOnClick = null,
  primaryBtnHierarchy = 'outline',
  secondaryBtnHierarchy = 'accent_primary',
  primaryBtnLoading = false,
  secondaryBtnLoading = false,
  isLoading = false,
  onClose = () => {},
  showCloseIcon = true,
  primaryBtnTestId = '',
  deleteTestId = ''
}) => {
  const className = 'c-ConfirmationModal';

  return (
    <div className={className}>
      {isOpen &&
        (isLoading ? (
          <LoadingCircle />
        ) : (
          <NPLModalNew
            isCloseOnBackdropClickAllowed={true}
            onClose={onClose}>
            {(closeModal) => (
              <NPLModalNew.Container size={500}>
                <NPLModalNew.Content className="relative font-poppins">
                  {showCloseIcon && (
                    <div
                      className="absolute top-12 right-12"
                      onClick={(e) => {
                        e.stopPropagation();
                        closeModal?.();
                      }}>
                      <NPLButton
                        dataTestId="close-modal-button"
                        hierarchy="plain"
                        leadIcon="x-close"
                        rounded
                        size="md"
                        isSecondary
                      />
                    </div>
                  )}
                  <div
                    className="font-poppins"
                    onClick={(e) => e.stopPropagation()}>
                    <p className="mb-24 text-heading-md font-semibold">
                      {title}
                    </p>
                    <p className="mb-24 text-body-md text-npl-text-icon-on-light-surface-secondary/65">
                      {subtitle}
                    </p>
                  </div>
                  <div className="flex flex-col items-end justify-end gap-12 md:flex-row md:gap-16">
                    {primaryBtnText && (
                      <div>
                        <NPLButton
                          data-testid={deleteTestId}
                          hierarchy={primaryBtnHierarchy}
                          onClick={async (e) => {
                            try {
                              await primaryBtnOnClick?.(e);
                            } finally {
                              closeModal?.();
                            }
                          }}
                          size="lg"
                          buttonText={primaryBtnText}
                          loading={primaryBtnLoading}
                        />
                      </div>
                    )}
                    {secondaryBtnText && (
                      <div>
                        <NPLButton
                          data-testid={primaryBtnTestId}
                          hierarchy={secondaryBtnHierarchy}
                          onClick={async (e) => {
                            try {
                              await secondaryBtnOnClick?.(e);
                            } finally {
                              closeModal?.();
                            }
                          }}
                          size="lg"
                          buttonText={secondaryBtnText}
                          loading={secondaryBtnLoading}
                        />
                      </div>
                    )}
                  </div>
                </NPLModalNew.Content>
              </NPLModalNew.Container>
            )}
          </NPLModalNew>
        ))}
    </div>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  primaryBtnText: PropTypes.string,
  primaryBtnClassName: PropTypes.string,
  secondaryBtnText: PropTypes.string,
  secondaryBtnClassName: PropTypes.string,
  primaryBtnOnClick: PropTypes.func,
  secondaryBtnOnClick: PropTypes.func,
  isLoading: PropTypes.bool
};

export default ConfirmationModal;
