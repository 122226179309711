import Auth from '@/modules/Auth';
import axios from 'axios';

import config from '@/utility/config';
import { downloadCSV } from '@/utility/helpers';
import { addQueryParamsToPath } from '@/utility/routesHelper';

import { getAxiosError, getAxiosErrorMsg } from '../helpers';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';
import { getCsvApiToken } from '../helpers/getCsvApiToken';
import protectedAxiosRequest from '../helpers/protectedAxiosRequest';
import publicAxiosRequest from '../helpers/publicAxiosRequest';
import { convertObjectToQueryString } from '../helpers/queryString';
import { axiosFetcher, protectedAxiosFetcher } from '../helpers/swr';
import unAuthorizedRequest from '../helpers/unAuthorizedRequest';

const { communityApiBasePath } = config;

// https://app.apifox.com/project/2830528
// GETs

/**
 * Get all challenges for a community
 * @param {string} communityId - The ID of the community
 * @returns {Promise} - Resolves to an object containing the challenge data
 */
export const getChallenges = async ({ communityId, pageNo, pageSize }) => {
  const params = {
    pageNo,
    pageSize
  };
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges?${convertObjectToQueryString(
      params
    )}`
  );
};

/**
 * Get a specific challenge for a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @returns {Promise} - Resolves to an object containing the challenge data
 */
export const getChallengeSWR = async ({ communityId, challengeId }) => {
  const params = {
    needCheckpoints: false
  };

  return await protectedAxiosFetcher(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}?${convertObjectToQueryString(
      params
    )}`
  );
};

/**
 * Get a specific challenge for a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @returns {Promise} - Resolves to an object containing the challenge data
 */
export const getChallenge = async ({
  communityId,
  challengeId,
  selectedAmount,
  affiliateCode
}) => {
  const token = Auth.getUserToken();

  const basePath = `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges
/${challengeId}`;

  const queryObj = {
    ...(selectedAmount || selectedAmount === 0 ? { selectedAmount } : {}),
    ...(affiliateCode ? { affiliateCode } : {})
  };

  const apiPath = addQueryParamsToPath(basePath, queryObj);

  if (!token) {
    return await unAuthorizedRequest(apiPath);
  }
  return await adminProtectedAxiosRequest.get(apiPath);
};

/**
 * Retrieves a challenge by its slug.
 *
 * @param {Object} options - The options for retrieving the challenge.
 * @param {string} options.communityLink - The community link.
 * @param {string} options.slug - The slug of the challenge.
 * @param {string|null} [options.accessToken=null] - The access token for the request.
 * @returns {Promise} A promise that resolves with the challenge data.
 */
export const getChallengeBySlug = async ({
  communityLink,
  slug,
  accessToken
}) => {
  const apiPath = `${communityApiBasePath}/api/v1/challenge/get-challenge-by-slug?slug=/${slug}&communityLink=/${communityLink}`;

  if (accessToken) {
    return await adminProtectedAxiosRequest.get(apiPath, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  return await publicAxiosRequest.get(apiPath);
};

/**
 * Retrieves the participants of a community challenge.
 *
 * @param {Object} options - The options for retrieving participants.
 * @param {string} options.communityId - The ID of the community.
 * @param {string} options.challengeId - The ID of the challenge.
 * @param {number|null} [options.checkpointIndexStart=null] - The index of the checkpoint to start from.
 * @param {string|null} [options.checkpointIdStart=null] - The ID of the checkpoint to start from.
 * @param {string} [options.searchString=''] - The search string to filter participants.
 * @param {Array<string>|null} [options.filterCheckpointIds=null] - The IDs of the checkpoints to filter participants.
 * @param {Array<string>|null} [options.filterCheckpointStatuses=null] - The statuses of the checkpoints to filter participants.
 * @param {number} [options.pageNo=1] - The page number of the results.
 * @param {number} [options.pageSize=20] - The number of participants per page.
 * @returns {Promise} A promise that resolves with the participants of the community challenge.
 */
export const getParticipants = async ({
  communityId,
  challengeId,
  checkpointIndexStart = null,
  checkpointIdStart = null, //for checkpoint filters - this property is going along with filterCheckpointIds
  searchString = '',
  filterCheckpointIds = null,
  filterCheckpointStatuses = null,
  pageNo = 1,
  pageSize = 20
}) => {
  const params = Object.fromEntries(
    Object.entries({
      pageNo,
      pageSize,
      checkpointIndexStart,
      checkpointIdStart,
      searchString,
      filterCheckpointIds,
      filterCheckpointStatuses
    }).filter(([_key, value]) => value)
  );
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants?${convertObjectToQueryString(
      params
    )}`
  );
};

export const getCheckpointsSWR = async ({
  communityId,
  challengeId,
  pageSize,
  pageNo,
  showUpsell = false,
  showCompletedCount = false
}) => {
  const params = {
    showUpsell: showUpsell,
    showCompletedCount: showCompletedCount
  };

  if (pageSize) {
    params.pageSize = pageSize;
  }

  if (pageNo) {
    params.pageNo = pageNo;
  }

  return await axiosFetcher(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints?${convertObjectToQueryString(
      params
    )}`
  );
};

export const getParticipantCheckpoints = async ({
  communityId,
  challengeId,
  pageSize = 1,
  pageNo = 1,
  accessToken = null
}) => {
  const params = {
    pageSize,
    pageNo
  };

  const { data, error } = await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participant-checkpoints?${convertObjectToQueryString(params)}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken ?? Auth.getUserToken()}`
      }
    }
  );
  return { data, error };
};

export const fetchParticipantByCommunityChallengeSlug = async ({
  challengeSlug,
  communitySlug
}) => {
  const params = {
    slug: challengeSlug,
    communityLink: communitySlug
  };

  const queryString = convertObjectToQueryString(params);

  return await protectedAxiosRequest(
    `${communityApiBasePath}/api/v1/challenge/check-participant-by-slug?${queryString}`
  );
};

export const getCheckpoints = async ({ communityId, challengeId }) => {
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints`
  );
};

/**
 * Get a specific checkpoint for a challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @param {string} checkpointId - The ID of the checkpoint
 * @returns {Promise} - Resolves to an object containing the checkpoint data
 */
export const getCheckpoint = async ({
  communityId,
  challengeId,
  checkpointId
}) => {
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints/${checkpointId}`
  );
};

/**
 * Get a specific participant for a challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @param {string} participantId - The ID of the participant
 * @returns {Promise} - Resolves to an object containing the participant data
 */
export const getParticipant = async ({
  communityId,
  challengeId,
  participantId
}) => {
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/${participantId}`
  );
};

export const getParticipantCheckpoint = async ({
  communityId = '',
  challengeId = '',
  participantId = '',
  checkpointId = ''
}) => {
  const { data, error } = await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/${participantId}/checkpoints/${checkpointId}/get-for-cm`
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data: data, error: false };
};

/**
 * Get a specific checkpoint for a participant in a challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @param {Array} participants - This array has 2 attributes participantObjectId and removalReason
 * @returns {Promise} - Resolves to an object containing the checkpoint data
 */
export const markParticipantsAsFailed = async ({
  communityId,
  challengeId,
  participants
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/remove`,
    {
      participants
    }
  );
};

/**
 * Get a specific checkpoint for a participant in a challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @param {Array} winners - This array has 2 attributes participantObjectId and declareWinner
 * @returns {Promise} - Resolves to an object containing the checkpoint data
 */
export const declareParticipantWinner = async ({
  communityId,
  challengeId,
  winners
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/declare-winner`,
    {
      winners
    }
  );
};

/**
 * Get the count of participants for a challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @returns {Promise} - Resolves to an object containing the participant count
 */
export const getParticipantCount = async ({
  communityId,
  challengeId
}) => {
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants`
  );
};

/**
 * Retrieves the end state of a challenge in a community.
 *
 * @param {Object} options - The options for retrieving the challenge end state.
 * @param {string} options.communityId - The ID of the community.
 * @param {string} options.challengeId - The ID of the challenge.
 * @returns {Promise} A promise that resolves to the challenge end state.
 */
export const getChallengeEndState = async ({
  communityId,
  challengeId
}) => {
  return await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/get-end-state`
  );
};

export const createChallenge = async ({ communityId, payload }) => {
  const response = await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges`,
    payload
  );
  if (response.error) {
    return { error: response.error, data: null };
  }
  return { error: null, data: response.data };
};

/**
 * Duplicate a checkpoint for a array of other checkpoints in a community
 * @param {string} communityId - The ID of the community
 * @param {object} payload - The payload containing the challenge data
 * @returns {Promise} - Resolves to an object containing the created challenge data
 */
export const duplicateCheckpoints = async ({
  communityId,
  challengeId,
  checkpointId,
  payload
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints/${checkpointId}/duplicate-checkpoint
    `,
    payload
  );
};

/**
 * Update an existing challenge in a community
 * @param {{ communityId: string, challengeId: string, payload: object }} params - The parameters object
 * @returns {Promise} - Resolves to an object containing the updated challenge data
 */
export const updateChallenge = async ({
  communityId,
  challengeId,
  payload
}) => {
  return await adminProtectedAxiosRequest.patch(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}`,
    payload
  );
};

/**
 * Publish a challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @returns {Promise} - Resolves to an object containing the updated challenge data
 */
export const publishChallenge = async ({ communityId, challengeId }) => {
  return await adminProtectedAxiosRequest.patch(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}`,
    {
      status: 'published'
    }
  );
};

/**
 * Duplicate a challenge within a community.
 *
 * @param {Object} options - The options for duplicating the challenge.
 * @param {string} options.communityId - The ID of the community.
 * @param {string} options.challengeId - The ID of the challenge to duplicate.
 * @returns {Promise} A promise that resolves with the response from the server.
 */
export const duplicateChallenge = async ({ communityId, challengeId }) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/duplicate`
  );
};

/**
 * Unpublish a challenge from a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @returns {Promise} - Resolves to an object containing the updated challenge data
 */
export const unpublishChallenge = async ({ communityId, challengeId }) => {
  return await adminProtectedAxiosRequest.patch(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}`,
    {
      status: 'draft'
    }
  );
};

/**
 * Delete a challenge from a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @returns {Promise} - Resolves to an object containing the updated challenge data
 */
export const deleteChallenge = async ({ communityId, challengeId }) => {
  return await adminProtectedAxiosRequest.patch(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}`,
    {
      status: 'deleted'
    }
  );
};

/**
 * Update the checkpoints of an existing challenge in a community
 * @param {string} communityId - The ID of the community
 * @param {string} challengeId - The ID of the challenge
 * @param {object} payload - The payload containing the updated checkpoint data
 * @returns {Promise} - Resolves to an object containing the updated challenge data
 */
export const updateChallengeCheckpoints = async ({
  communityId,
  challengeId,
  payload
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/update-checkpoints`,
    payload
  );
};

/**
 * Adds participants to a community challenge.
 *
 * @param {Object} options - The options for adding participants.
 * @param {string} options.communityId - The ID of the community.
 * @param {string} options.challengeId - The ID of the challenge.
 * @param {Object} options.payload - The payload containing participant data.
 * @returns {Promise} A promise that resolves with the response from the server.
 */
export const addParticipants = async ({
  communityId,
  challengeId,
  payload
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants`,
    payload
  );
};

/**
 * Updates the status of a participant in a community challenge.
 *
 * @param {Object} options - The options for updating the participant status.
 * @param {string} options.communityId - The ID of the community.
 * @param {string} options.challengeId - The ID of the challenge.
 * @param {string} options.participantId - The ID of the participant.
 * @param {string} options.status - The new status for the participant.
 * @returns {Promise} A promise that resolves to the updated participant status.
 */
export const updateParticipantStatus = async ({
  communityId,
  challengeId,
  participantId,
  status
}) => {
  return await adminProtectedAxiosRequest.put(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/${participantId}`,
    {
      status
    }
  );
};

export const saveChallengeGPTLead = async ({ email }) => {
  try {
    const payload = {
      name: email,
      email,
      source: 'challengeGPT',
      courseCode: 'CHALLENGE_GPT'
    };

    const path =
      'https://static.nasacademy.com/api/v1/course/application/application-leads';

    return await axios.post(path, payload);
  } catch (error) {
    return getAxiosError(error);
  }
};

export const checkParticipantByCommunityChallengeSlug = async ({
  challengeSlug,
  communitySlug,
  accessToken
}) => {
  const params = {
    slug: challengeSlug,
    communityLink: communitySlug
  };

  const queryString = convertObjectToQueryString(params);

  //cannot use axios due to server's API call
  return await fetch(
    `${communityApiBasePath}/api/v1/challenge/check-participant-by-slug?${queryString}`,
    {
      method: 'GET',
      'Content-Type': 'application/json',
      headers: {
        Authorization: `Bearer ${accessToken}` //this API is called on the server, so we need to pass the accessToken
      }
    }
  ).then((response) => response.json());
};

export const updateParticipantCheckpoint = async ({
  communityId,
  challengeId,
  participantId,
  checkpointId,
  bodyParams
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/${participantId}/checkpoints/${checkpointId}/participate-checkpoint`,
    bodyParams
  );
};

export const addFeed = async ({
  communityId,
  challengeId,
  entryId,
  requestBody
}) => {
  return await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/feed/entries/${entryId}`,
    requestBody
  );
};

export const getUserCheckpointData = async ({
  communityId,
  challengeId,
  participantId,
  checkpointId
}) => {
  return await adminProtectedAxiosRequest.get(`${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/${participantId}/checkpoints/${checkpointId}
`);
};

export const exportParticipantsToCsv = async ({
  communityId,
  challengeId,
  params
}) => {
  const { data, error } = await getCsvApiToken(communityId);
  if (error) {
    return { data: null, error: getAxiosError(error) };
  }
  const queryString = convertObjectToQueryString({
    ...params,
    token: data
  });
  const apiPath = `${config.communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/participants/export/csv?${queryString}`;
  downloadCSV(apiPath);
  return { data: null, error: false };
};

export const saveCheckpointEvent = async ({
  communityId,
  challengeId,
  checkpointId,
  payload
}) => {
  const path = `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints/${checkpointId}/event`;

  const { data, error } = await adminProtectedAxiosRequest.put(
    path,
    payload
  );

  if (error) {
    return { data: null, error: getAxiosError(error) };
  }

  return { data, error: null };
};

export const deleteCheckpointEvent = async ({
  communityId,
  challengeId,
  checkpointId
}) => {
  const path = `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints/${checkpointId}/event`;

  const { data, error } = await adminProtectedAxiosRequest.delete(path);

  if (error) {
    return { data: null, error: getAxiosError(error) };
  }

  return { data, error: null };
};

export const createUpsell = async ({ communityId, payload }) => {
  const { error } = await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/communities/${communityId}/upsells`,
    payload
  );
  return { error };
};

export const updateCheckpointDates = async ({
  communityId,
  challengeId,
  checkpointId,
  payload
}) => {
  const path = `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints/${checkpointId}/update-dates`;

  const { data, error } = await adminProtectedAxiosRequest.post(
    path,
    payload
  );

  if (error) {
    return { data: null, error: getAxiosError(error) };
  }

  return { data, error: null };
};

export const updateCheckpointInBulk = async (
  communityId,
  challengeId,
  payload
) => {
  const path = `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/bulk-update-checkpoints`;

  const { data, error } = await adminProtectedAxiosRequest.post(
    path,
    payload
  );

  return { data, error };
};

export const calculateCheckpointDates = async (
  communityId,
  challengeId,
  checkpointId,
  payload
) => {
  const path = `${communityApiBasePath}/api/v1/challenge/community/${communityId}/challenges/${challengeId}/checkpoints/${checkpointId}/calculate-dates`;

  const { data, error } = await adminProtectedAxiosRequest.post(
    path,
    payload
  );

  return { data, error };
};
