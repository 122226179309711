import { addDays } from 'date-fns';
import { FC, useEffect, useState } from 'react';

import withComponentClassName from '@/utility/withComponentClassName';

import {
  getCheckpointsSWR,
  getParticipantCheckpoints
} from '@/services/community/CommunityChallengesService';

import InfiniteList from '@/components/common/InfiniteList';
import SideDrawer from '@/components/common/SideDrawer';
import { showErrorToast } from '@/components/common/ToastContainer';

import useQueryParams from '@/hooks/useQueryParams';

import { PARTICIPANT_PROGRAM_ITEM_STATUS } from '@/pages/portal/challenges/constants';

import ChallengeCheckpointPage from '../ChallengeCheckpointPage';
import {
  CheckpointItemType,
  CheckpointType,
  CommunityInfoType,
  CurrentCheckpointDataType,
  MappedCheckpointItem
} from '../types';
import { checkIfCheckpointIsCompletedByStatus } from '../utils';
import CheckpointPreviewCard, {
  CheckpointPreviewCardSkeleton
} from './CheckpointPreviewCard';

export const DEFAULT_CHECKPOINT_PAGE_NO = 1;
export const CHECKPOINT_PAGE_SIZE = 20;

type SelectedCheckpointDataType = {
  checkpointData: MappedCheckpointItem;
  completionStatus: (typeof PARTICIPANT_PROGRAM_ITEM_STATUS)[keyof typeof PARTICIPANT_PROGRAM_ITEM_STATUS];
};

type CheckpointListDisplayProps = {
  challengeId: string;
  challengeTitle: string;
  checkpointCount: number;
  communityId: string;
  communityInfo: CommunityInfoType;
  completeCheckpoint?: (
    updatedCheckpoint: CurrentCheckpointDataType
  ) => void;
  currentCheckpoint?: CurrentCheckpointDataType;
  enableCheckpointSubmissionAfterDeadline?: boolean;
  hasChallengeEnded?: boolean;
  hasManagerAccess?: boolean;
  isChallengeLocked?: boolean;
  isFeedEnabled?: boolean;
  isLeaderboardEnabled?: boolean;
  onCloseCheckpointDetails?: () => void;
  onCompleteFeed?: () => void;
  openCheckpointDetails?: (checkpointId: string) => void;
  participantCheckpoints?: CheckpointItemType[];
  participantId?: string;
  participantJoinedDate?: string;
  selectedCheckpoint?: SelectedCheckpointDataType;
  setParticipantData?: (_data: unknown) => void;
  setSelectedCheckpoint?: (checkpoint: SelectedCheckpointDataType) => void;
  stepByStepUnlocking?: boolean;
};

const CheckpointListDisplay: FC<CheckpointListDisplayProps> = ({
  checkpointCount,
  challengeId,
  communityId,
  participantId,
  stepByStepUnlocking = true,
  currentCheckpoint,
  participantCheckpoints,
  isChallengeLocked,
  isLeaderboardEnabled,
  // hasManagerAccess,
  completeCheckpoint,
  setParticipantData,
  isFeedEnabled,
  hasChallengeEnded,
  enableCheckpointSubmissionAfterDeadline,
  onCompleteFeed,
  participantJoinedDate,
  challengeTitle,
  selectedCheckpoint,
  setSelectedCheckpoint,
  openCheckpointDetails,
  onCloseCheckpointDetails
}) => {
  const [pageNo, setPageNo] = useState<number>(DEFAULT_CHECKPOINT_PAGE_NO);
  const [checkpoints, setCheckpoints] = useState<MappedCheckpointItem[]>(
    []
  );
  const { currentRouterQuery } = useQueryParams();
  const [isLoadingCheckpoints, setIsLoadingCheckpoints] = useState(false);

  //legacy logic - deprecate after the release
  const fetchCheckpoints = async (updatedPageNo: number) => {
    setIsLoadingCheckpoints(true);
    const { data, error } = await getCheckpointsSWR({
      challengeId,
      communityId,
      pageNo: updatedPageNo,
      pageSize: CHECKPOINT_PAGE_SIZE
    });
    setIsLoadingCheckpoints(false);

    if (error) {
      showErrorToast(error);
      return;
    }

    const mappedData = data.map((item: CheckpointType) => {
      const participantCheckpoint =
        participantCheckpoints?.find(
          (checkpoint) => checkpoint.itemObjectId === item._id
        ) || {};
      return {
        ...participantCheckpoint,
        ...item
      };
    });

    setCheckpoints((prevCheckpoints) => [
      ...prevCheckpoints,
      ...mappedData
    ]);
  };

  //new logic to fetch participant checkpoints with statuses
  const fetchParticipantCheckpoints = async (updatedPageNo: number) => {
    setIsLoadingCheckpoints(true);
    const { data, error } = await getParticipantCheckpoints({
      challengeId,
      communityId,
      pageNo: updatedPageNo,
      pageSize: CHECKPOINT_PAGE_SIZE
    });

    if (error) {
      showErrorToast(error);
      setIsLoadingCheckpoints(false);
      return;
    }

    const { checkpoints } = data?.data ?? {};

    setCheckpoints((prevCheckpoints) => [
      ...prevCheckpoints,
      ...checkpoints
    ]);
    setIsLoadingCheckpoints(false);
  };

  const updateCompletionStatusWithUpdatedCheckpoint = (
    updatedCheckpoint: CurrentCheckpointDataType
  ) => {
    setCheckpoints((prevCheckpoints) =>
      prevCheckpoints.map((checkpoint) =>
        checkpoint._id === updatedCheckpoint.itemObjectId
          ? {
              ...checkpoint,
              completionStatus: updatedCheckpoint.completionStatus
            }
          : checkpoint
      )
    );
  };

  const updateStatesAfterCheckpointCompletion = async () => {
    // If user is on checkpoint 31
    // User only loads until checkpoint 20
    // If user press on the checkpoint banner 'Start checkpoint'
    // We have no way of knowing the next checkpoint
    // Thats why need to call this api to get it
    const { data, error } = await getParticipantCheckpoints({
      challengeId,
      communityId,
      pageNo: 1,
      pageSize: CHECKPOINT_PAGE_SIZE
    });
    if (error) {
      showErrorToast(error);
      return;
    }
    // Set newCurrentCheckpointData in participant data, so user can instantly go on to the next
    const newCurrentCheckpointData = data?.data?.currentCheckpointData;
    setParticipantData((prev) => {
      return {
        ...prev,
        currentCheckpointData: newCurrentCheckpointData,
        participant: data?.data?.participant
      };
    });

    // If newCurrentCheckpoint is already in the list, unlock it
    // Unlock the new current checkpoint with the api response
    if (newCurrentCheckpointData?.index < checkpoints.length) {
      setCheckpoints((prev) => {
        return prev.map((checkpoint) =>
          checkpoint._id === newCurrentCheckpointData._id
            ? {
                ...checkpoint,
                locked:
                  new Date() >
                  new Date(newCurrentCheckpointData?.startTime)
                    ? false
                    : true
              }
            : checkpoint
        );
      });
    }
  };

  useEffect(() => {
    if (currentRouterQuery?.checkpointId && openCheckpointDetails) {
      openCheckpointDetails(currentRouterQuery?.checkpointId as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouterQuery?.checkpointId]);

  const getCheckpointStartTime = (checkpoint) => {
    const { unlockAfterXDays } = checkpoint || {};
    let { startTime } = checkpoint || {};

    if (!startTime) {
      startTime = addDays(
        participantJoinedDate ?? new Date(),
        unlockAfterXDays ?? 0
      );
    }

    return startTime;
  };

  const isCheckpointLocked = (checkpoint): boolean => {
    if (!checkpoint) {
      return true;
    }

    const isCheckpointCompleted = checkIfCheckpointIsCompletedByStatus(
      checkpoint?.completionStatus
    );

    // if the challenge is not locked and the checkpoint is completed, we don't need to lock the checkpoint
    if (!isChallengeLocked && isCheckpointCompleted) {
      return false;
    }

    if (!currentCheckpoint && !participantId) {
      // CM accessing the challenge
      const startTime = getCheckpointStartTime(checkpoint);

      const isFutureCheckpoint = new Date(startTime) > new Date();

      const locked = isFutureCheckpoint || isChallengeLocked;

      return locked;
    }

    //override locked status if the checkpoint is the current participant checkpoint
    if (Object.hasOwn(checkpoint, 'locked')) {
      return checkpoint.locked;
    }

    const startTime = getCheckpointStartTime(checkpoint);

    // const isFutureCheckpoint = new Date(checkpoint.startTime) > new Date();
    const isFutureCheckpoint = new Date(startTime) > new Date();

    const currentCheckpointStartTime =
      getCheckpointStartTime(currentCheckpoint);

    const isNextCheckpoint =
      currentCheckpointStartTime &&
      new Date(startTime) > new Date(currentCheckpointStartTime);

    const isCurrentCheckpointCompleted =
      checkIfCheckpointIsCompletedByStatus(
        currentCheckpoint?.completionStatus
      );

    const locked =
      !participantId || //CM access
      (stepByStepUnlocking &&
        !isCurrentCheckpointCompleted &&
        isNextCheckpoint) || //if the current checkpoint is not completed, then the next checkpoint is locked
      isChallengeLocked || //if the challenge is locked, then all checkpoints are locked
      isFutureCheckpoint; //future checkpoints are locked

    return locked;
  };

  const renderedCheckpoints = checkpoints.map(
    (checkpoint: MappedCheckpointItem) => {
      const isCurrentParticipantCheckpoint =
        currentCheckpoint?._id === checkpoint._id;

      const isCompleted = checkIfCheckpointIsCompletedByStatus(
        checkpoint.completionStatus
      );

      const locked = isCheckpointLocked(checkpoint);

      let completionStatus =
        currentCheckpoint?.itemObjectId === checkpoint._id
          ? currentCheckpoint.completionStatus
          : null;

      if (Object.hasOwn(checkpoint, 'completionStatus')) {
        completionStatus = checkpoint.completionStatus;
      }

      return {
        ...checkpoint,
        isCurrentParticipantCheckpoint,
        isCompleted,
        locked,
        completionStatus
      };
    }
  );

  const lastCheckpointIndex = renderedCheckpoints.length - 1;

  const infiniteListProps = {
    items: renderedCheckpoints,
    hasMore: checkpoints?.length < checkpointCount,
    isLoading: isLoadingCheckpoints,
    renderSkeleton: () => <CheckpointPreviewCardSkeleton />,
    loadMore: () => {
      participantId
        ? fetchParticipantCheckpoints(pageNo)
        : fetchCheckpoints(pageNo);

      setPageNo(pageNo + 1);
    }
  };

  return (
    <div className="relative">
      <InfiniteList {...infiniteListProps}>
        {(checkpoint: MappedCheckpointItem, index: number) => (
          <CheckpointPreviewCard
            checkpoint={checkpoint}
            key={index}
            hasDivider={index < lastCheckpointIndex}
            completionStatus={checkpoint.completionStatus}
            participantId={participantId}
            isCheckpointLocked={checkpoint.locked}
            isCheckpointCompleted={checkpoint.isCompleted}
            onClick={
              openCheckpointDetails
                ? () => openCheckpointDetails(checkpoint._id)
                : undefined
            }
            isCurrentParticipantCheckpoint={
              checkpoint.isCurrentParticipantCheckpoint
            }
            participantJoinedDate={participantJoinedDate}
          />
        )}
      </InfiniteList>
      {selectedCheckpoint && (
        <SideDrawer maxWidth={10000} onClose={onCloseCheckpointDetails}>
          <ChallengeCheckpointPage
            communityId={communityId}
            challengeId={challengeId}
            checkpointCount={checkpointCount}
            participantId={participantId}
            selectedCheckpointData={selectedCheckpoint}
            isCheckpointLocked={isCheckpointLocked({
              ...selectedCheckpoint?.checkpointData,
              completionStatus: selectedCheckpoint?.completionStatus // BE returns completionStatus in parent object
            })}
            closeSideDrawer={onCloseCheckpointDetails}
            setSelectedCheckpoint={setSelectedCheckpoint}
            onCompleteCheckpoint={async (
              updatedCheckpoint: CurrentCheckpointDataType
            ) => {
              updateCompletionStatusWithUpdatedCheckpoint(
                updatedCheckpoint
              );
              completeCheckpoint?.(updatedCheckpoint);
              await updateStatesAfterCheckpointCompletion();
            }}
            isLeaderboardEnabled={isLeaderboardEnabled}
            isFeedEnabled={isFeedEnabled}
            hasChallengeEnded={hasChallengeEnded}
            enableCheckpointSubmissionAfterDeadline={
              enableCheckpointSubmissionAfterDeadline
            }
            onCompleteFeed={onCompleteFeed}
            participantJoinedDate={participantJoinedDate}
            challengeTitle={challengeTitle}
            // new
            infiniteListProps={infiniteListProps}
            lastCheckpointIndex={lastCheckpointIndex}
            openCheckpointDetails={openCheckpointDetails}
          />
        </SideDrawer>
      )}
    </div>
  );
};

export default withComponentClassName(CheckpointListDisplay);
