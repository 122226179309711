import config from '@/utility/config';
import { t } from '@/utility/localization';

import { videoTypes } from '@/contexts/LibrarySetupContext/constants';

import type { IconName } from '@/components/npl/Icon';

type IconProps = {
  backgroundColor: string;
  iconColor: string;
  iconName: IconName;
};

export type GamificationPoint = {
  iconProps: IconProps;
  id:
    | 'completionPoint'
    | 'completionResponseTimePoint'
    | 'completion3SequentialConsecutivePoint'
    | 'celebrationPoint'
    | 'commentPoint'
    | 'bonusPoint';
  tailTextColor: string;
  title: string;
};

export type GamificationPointType =
  | 'checkpoint'
  | 'streak'
  | 'speed'
  | 'bonus'
  | 'engagement';

export type GamificationPointsConstants = {
  iconProps: IconProps;
  id: GamificationPoint['id'];
  tailTextColor: string;
  title: string;
  type: GamificationPointType;
}[];

export const getChallengeTemplates = () => [
  {
    label: t('7-day-challenge'),
    subtitle: t('ignite-a-week-of-excitement'),
    icon: 'clock-fast-forward',
    backgroundColorClassName: 'bg-npl-success-light-3',
    iconFillClassName: 'fill-npl-success-light-11',
    value: {
      challengeDurationInDays: 7,
      checkpointDurationInDays: 1,
      titlePlaceholder: t('name-your-7-day-challenge')
    }
  },
  {
    label: t('30-day-challenge'),
    subtitle: t('set-a-month-long-quest'),
    icon: 'calendar-check-01',
    backgroundColorClassName: 'bg-npl-blurple-light-3',
    iconFillClassName: 'fill-npl-blurple-light-11',
    value: {
      challengeDurationInDays: 30,
      checkpointDurationInDays: 1,
      titlePlaceholder: t('name-your-30-day-challenge')
    }
  },
  {
    label: t('6-month-challenge'),
    subtitle: t('build-an-epic-half-year-campaign'),
    icon: 'trophy-01',
    backgroundColorClassName: 'bg-npl-orange-light-3',
    iconFillClassName: 'fill-npl-orange-light-11',
    value: {
      challengeDurationInDays: 180,
      checkpointDurationInDays: 30,
      titlePlaceholder: t('name-your-6-month-challenge')
    }
  }
];

export const CHALLENGE_PUBLICATION_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published'
};

export const CHECKPOINT_RUNNING_STATUSES = {
  NOT_STARTED: 'notStarted',
  ON_GOING: 'onGoing',
  ENDED: 'ended',
  UPSELL: 'upsell'
};

export const getCheckpointDisplayStatuses = (): {
  [key: string]: {
    backgroundColorClassName: string;
    iconClassName: string;
    iconName: IconName;
    status: string;
  };
} => ({
  [CHECKPOINT_RUNNING_STATUSES.NOT_STARTED]: {
    status: CHECKPOINT_RUNNING_STATUSES.NOT_STARTED,
    iconName: 'flag-01',
    iconClassName: 'fill-npl-text-icon-on-light-surface-tertiary',
    backgroundColorClassName: 'bg-white-default'
  },
  [CHECKPOINT_RUNNING_STATUSES.ON_GOING]: {
    status: CHECKPOINT_RUNNING_STATUSES.ON_GOING,
    iconName: 'flag-01',
    iconClassName: 'fill-npl-yellow-light-solid-11',
    backgroundColorClassName: 'bg-npl-yellow-light-solid-3'
  },
  [CHECKPOINT_RUNNING_STATUSES.ENDED]: {
    status: CHECKPOINT_RUNNING_STATUSES.ENDED,
    iconName: 'check',
    iconClassName: 'fill-npl-success-light-11',
    backgroundColorClassName: 'bg-npl-success-light-3'
  },
  [CHECKPOINT_RUNNING_STATUSES.UPSELL]: {
    status: CHECKPOINT_RUNNING_STATUSES.UPSELL,
    iconName: 'diamond-01',
    iconClassName: 'fill-npl-text-icon-on-light-surface-tertiary',
    backgroundColorClassName: 'bg-npl-neutral-light-solid-2'
  }
});

export const CHALLENGE_RUNNING_STATUSES = {
  NOT_STARTED: 'notStarted',
  ON_GOING: 'onGoing',
  ENDED: 'ended'
};

export const getChallengerProgressStatuses = () => ({
  [CHALLENGE_RUNNING_STATUSES.NOT_STARTED]: {
    status: CHALLENGE_RUNNING_STATUSES.NOT_STARTED,
    label: t('not-started'),
    backgroundColorClassName: 'bg-npl-neutral-light-solid-3',
    textColorClassName: 'text-npl-neutral-light-solid-11'
  },
  [CHALLENGE_RUNNING_STATUSES.ON_GOING]: {
    status: CHALLENGE_RUNNING_STATUSES.ON_GOING,
    label: t('on-going'),
    backgroundColorClassName: 'bg-npl-success-light-3',
    textColorClassName: 'text-npl-success-light-11'
  },
  [CHALLENGE_RUNNING_STATUSES.ENDED]: {
    status: CHALLENGE_RUNNING_STATUSES.ENDED,
    label: t('ended'),
    backgroundColorClassName: 'bg-npl-orange-light-3',
    textColorClassName: 'text-npl-orange-light-11'
  }
});

export const PARTICIPANT_PROGRAM_STATUS = {
  PARTICIPATED: '2_participated',
  COMPLETED: '3_completed',
  WINNER: '4_winner',
  KICKED_OUT: '1_kicked_out'
};

//checkpoint statuses
export const PARTICIPANT_PROGRAM_ITEM_STATUS = {
  FAILED: '0_failed',
  INCOMPLETE: '1_incomplete',
  COMPLETED: '3_completed',
  LATE_COMPLETED: '2_late_completed'
};

//the activity status is a combination of the participant status with dates and checkpoint statuses
export const ACTIVITY_STATUS = {
  ...PARTICIPANT_PROGRAM_ITEM_STATUS,
  PARTICIPANT_COMPLETED: 'participant_completed',
  PARTICIPANT_WON: 'participant_won',
  PARTICIPANT_KICKED_OUT: 'participant_kicked_out',
  PARTICIPANT_JOINED: 'participant_joined'
};

export const DEFAULT_CHALLENGE_COVER_URL = `${config.imagesBasePath}/nasIO/portal/png/default-challenge-cover.png`;

export const PRICING_VALUES = {
  FREE: 'free',
  PAID: 'paid'
};

export const CHALLENGES_PAGE_SIZE = 20;
export const CHECKPOINT_PAGE_SIZE = 20;
export const DEFAULT_CHALLENGES_PAGE_INDEX = 1;
export const DEFAULT_CHECKPOINTS_PAGE_INDEX = 1;

export const CHALLENGE_UPDATE_SECTIONS = {
  OVERVIEW: 'OVERVIEW',
  PRICING_AND_ACCESS: 'PRICING_AND_ACCESS',
  HOST_SELECTION: 'HOST_SELECTION',
  FEED_SETTINGS: 'FEED_SETTINGS',
  CHAT_GROUP: 'CHAT_GROUP',
  MORE_OPTIONS: 'MORE_OPTIONS'
};

// TODO-neil
export const isFileSupported = (type) => {
  return [
    //  FILE_TYPE.FILE,
    ...videoTypes
    // FILE_TYPE.IMG,
    // FILE_TYPE.AUDIO
  ].includes(type);
};

export const CHALLENGE_CONFIRMATION_MODALS = {
  UNPUBLISH: 'UNPUBLISH',
  DELETE: 'DELETE'
};

export const getFilterableCheckpointStatuses = () => [
  {
    label: t('completed'),
    value: PARTICIPANT_PROGRAM_ITEM_STATUS.COMPLETED
  },
  {
    label: t('late-completion'),
    value: PARTICIPANT_PROGRAM_ITEM_STATUS.LATE_COMPLETED
  },
  {
    label: t('incomplete'),
    value: PARTICIPANT_PROGRAM_ITEM_STATUS.INCOMPLETE
  }
];

export const getParticipantFailureMarkingReasonOptions = () => [
  {
    label: t('participant-did-not-fulfil-challenge-requirements'),
    value: t('participant-did-not-fulfil-challenge-requirements')
  },
  {
    label: t('participant-opted-out-of-the-challenge'),
    value: t('participant-opted-out-of-the-challenge')
  },
  {
    label: t('other-reasons'),
    value: t('other-reasons'),
    isOther: true //this property is to decide if the other input should be shown
  }
];

export const CHECKPOINT_QUESTION_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document'
};

export const CHECKPOINT_LEADERBOARD_TYPE = {
  FASTEST_COMPLETION: 'fastest_checkpoint_completion'
};

export const CHECKPOINT_ACTION_TYPE = {
  DRAFT: 'draft',
  COMPLETE: 'complete',
  EDIT: 'edit'
};

export const CHECKPOINT_SUBMISSION_STATUS_TYPES = {
  DRAFT: '0_draft',
  INCOMPLETE: '1_incomplete',
  COMPLETED: '3_completed',
  LATE_COMPLETED: '2_late_completed'
};

export const SUBMISSION_FORM_STATES = {
  EDITING_SUBMISSION: 'editing_submission',
  VIEWING: 'viewing',
  COMPLETING: 'completing',
  EDITING_DRAFT: 'editing_draft'
};

export const DEFAULT_CHALLENGE_DURATION_DAYS = 7;
export const DEFAULT_CHECKPOINT_DURATION_DAYS = 1;

export const CHALLENGE_TYPES = {
  FIXED: 'FIXED',
  ALWAYS_ON: 'ALWAYS_ON'
};

export const getGamificationBonusPointsList = () => [
  { value: 'GOOD', emoji: '🙂', title: t('good'), points: 50 },
  { value: 'GREAT', emoji: '😀', title: t('great'), points: 150 },
  { value: 'AMAZING', emoji: '🤩', title: t('amazing'), points: 250 }
];
