import { useState } from 'react';

import { t } from '@/utility/localization';

import { uploadImageWithMultipart } from '@/services/uploadService';
import { getPresignedUrlOfVideo } from '@/services/videoUploadService';

import { isFileSupported } from '@/pages/portal/challenges/constants';

import { showErrorToast, showSuccessToast } from '../ToastContainer';
import { compressVideo, getVideoThumbnail } from './utils';

function useVideoUploader({ communityId, entityId, entityType }) {
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [videoTranscodingProgress, setVideoTranscodingProgress] =
    useState(0);

  const handleUploadVideo = async ({
    file,
    otherBodyParams,
    isCompressionNeeded,
    hasThumbnailUpload
  }) => {
    if (!file) {
      showErrorToast(t('no-file-to-upload'));
      return;
    }

    const compressedVideoFile = isCompressionNeeded
      ? await compressVideo(file, ({ progress }) => {
          setVideoTranscodingProgress(progress * 100);
        })
      : file;

    setUploadingVideo(true);
    const mineType = compressedVideoFile.type;
    const isFileTypeSupported = isFileSupported(mineType);

    if (!isFileTypeSupported) {
      showErrorToast(t('file-type-not-supported'));
    }

    const { data } = await getPresignedUrlOfVideo({
      filename: file.name,
      communityId,
      entityId,
      entityType,
      mimeType: mineType,
      otherBodyParams
    });

    const videoLink = data?.data?.fileKey;
    const linkToUploadVideoToS3 = data?.data?.url;

    // make sure that response return 200 before returning the cloudfront url

    const response = await fetch(linkToUploadVideoToS3, {
      method: 'PUT',
      headers: {
        'Content-Type': encodeURI(mineType)
      },
      body: compressedVideoFile
    });

    if (response.status === 200) {
      const uploadedData = {
        videoLink,
        fileSize: compressedVideoFile?.size
      };

      if (hasThumbnailUpload) {
        const videoThumbnail = await getVideoThumbnail(file);

        const { data } = await uploadImageWithMultipart({
          useCase: 'video_thumbnail',
          image: videoThumbnail,
          associatedEntityId: entityId
        });

        if (data?.imageUrl) {
          uploadedData.videoThumbnailLink = data?.imageUrl;
        }
      }

      setUploadingVideo(false);
      showSuccessToast(t('video-uploaded-successfully'));
      return uploadedData;
    } else {
      setUploadingVideo(false);
      showErrorToast(t('an-error-occured-please-try-again'));
    }
  };

  return { handleUploadVideo, uploadingVideo, videoTranscodingProgress };
}

export default useVideoUploader;
