import classNames from 'classnames';
import { addDays } from 'date-fns';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import { trackGAEvent } from '@/utility/analytics';
import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import { isEditorEmpty } from '@/components/common/Editor/utils/common';
import InfiniteList from '@/components/common/InfiniteList';
import PopOverModal from '@/components/common/PopOverModal';
import {
  showErrorToast,
  showSuccessToast
} from '@/components/common/ToastContainer';
import NPLButton from '@/components/npl/NPLButton';

import {
  CHECKPOINT_ACTION_TYPE,
  CHECKPOINT_SUBMISSION_STATUS_TYPES
} from '@/pages/portal/challenges/constants';
import ConfirmationModal from '@/pages/portal/events/components/ConfirmationModal';

import ChallengeCheckpointPageHeader from './ChallengeCheckpointPageHeader';
import ChallengeCheckpointPageCheckpointInfo from './components/ChallengeCheckpointPageCheckpointInfo';
import CheckpointPageFooter from './components/CheckpointPage/CheckpointPageFooter';
import CheckpointSubmissionForm from './components/CheckpointPage/CheckpointSubmissionForm';
import FeedFormScreen from './components/CheckpointPage/FeedFormScreen';
import CheckpointPreviewCard from './components/CheckpointPreviewCard';
import useCheckpointSubmissionForm from './hooks/useCheckpointSubmissionForm';

const CheckpointSuccessScreen = dynamic(
  () => import('./components/CheckpointPage/CheckpointSuccessScreen'),
  {
    ssr: false
  }
);

function ChallengeCheckpointPage({
  communityId,
  challengeId,
  participantId,
  selectedCheckpointData,
  closeSideDrawer,
  onCompleteCheckpoint,
  setSelectedCheckpoint,
  isLeaderboardEnabled,
  isFeedEnabled,
  hasChallengeEnded,
  enableCheckpointSubmissionAfterDeadline,
  onCompleteFeed,
  isPreview = false,
  checkpointCount,
  participantJoinedDate,
  challengeTitle,
  // new
  infiniteListProps,
  lastCheckpointIndex,
  openCheckpointDetails,
  isCheckpointLocked
}) {
  const [isSuccessScreenOpen, setIsSuccessScreenOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFeedFormOpen, setIsFeedFormOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [resourceToPreview, setResourceToPreview] = useState(null);
  const [showCheckpointSidenav, setShowCheckpointSidenav] = useState(true);
  const {
    submission,
    completionStatus,
    checkpointData,
    completionPoint,
    completionResponseTimePoint,
    completion3SequentialConsecutivePoint,
    leaderboardInfo
  } = selectedCheckpointData || {};

  const { isGtEqMd, isGtEqLg } = useWindowWidthContext();
  const {
    _id: checkpointId,
    title,
    submissionRequired,
    description,
    coverVideo,
    completedProfilePics,
    completedCount,
    submissionQuestions,
    event,
    attachmentItems,
    unlockAfterXDays
  } = checkpointData || selectedCheckpointData || {};
  let { startTime } = checkpointData || selectedCheckpointData || {};
  const pointsEarned = selectedCheckpointData?.totalPoints ?? 0;

  const {
    handleUpdateParticipantCheckpoint,
    openSubmissionForm,
    isSubmissionFormOpen,
    resetStateAndCloseForm,
    handleInputChange,
    formData,
    setSubmissionFormState,
    submissionFormState,
    initializeFormData,
    isUpdatingSubmission,
    inputError,
    checkForChangesInQuestions,
    currentSubmissionQuestions,
    isSubmissionChanged,
    feedFormData,
    setFeedFormData,
    submitFeedForm
  } = useCheckpointSubmissionForm({
    communityId,
    challengeId,
    participantId,
    checkpointId,
    submissionQuestions,
    submittedAnswers: submission?.answers,
    onCompleteCheckpoint,
    setSelectedCheckpoint,
    isFeedEnabled,
    isPreview
  });

  const videoData = coverVideo?.mediaData?.video;

  const toggleCheckpointSidenav = () => {
    setShowCheckpointSidenav((prevState) => !prevState);
  };

  const handleSaveDraft = async () => {
    const { error } = await handleUpdateParticipantCheckpoint(
      CHECKPOINT_ACTION_TYPE.DRAFT
    );
    if (error) {
      showErrorToast(error);
      return;
    }
    showSuccessToast(t('saved-as-draft'));
    // Needed to close popover
    setShowConfirmation(false);
  };

  const hasContent =
    videoData ||
    !isEditorEmpty(description) ||
    event ||
    attachmentItems?.length > 0;

  // Hide if submission is open and if theres no description/video or its in mobile view
  const hideContentAndDescription =
    isSubmissionFormOpen && (!hasContent || !isGtEqLg);
  const showContentAndDescription = !hideContentAndDescription;

  const isCheckpointCompleted = [
    CHECKPOINT_SUBMISSION_STATUS_TYPES.COMPLETED,
    CHECKPOINT_SUBMISSION_STATUS_TYPES.LATE_COMPLETED
  ].includes(completionStatus);

  const isParticipant = Boolean(participantId);

  if (!startTime) {
    startTime = addDays(
      participantJoinedDate ?? new Date(),
      unlockAfterXDays ?? 0
    );
  }

  // if start time is less than or equal to current date, means challenge started
  const hasCheckpointStarted = new Date(startTime) <= new Date();

  const renderCheckpointFooter = () => (
    <div
      className={classNames('fixed lg:absolute left-0 right-0 bottom-0')}>
      <CheckpointPageFooter
        checkpointId={checkpointId}
        completedProfilePics={completedProfilePics}
        isUploading={isUploading}
        completedCount={completedCount}
        isCheckpointCompleted={isCheckpointCompleted}
        isLeaderboardEnabled={isLeaderboardEnabled}
        submissionRequired={submissionRequired}
        showSuccessScreen={() => setIsSuccessScreenOpen(true)}
        handleCompleteCheckpoint={handleUpdateParticipantCheckpoint}
        openSubmissionForm={openSubmissionForm}
        isSubmissionFormOpen={isSubmissionFormOpen}
        completionStatus={completionStatus}
        setSubmissionFormState={setSubmissionFormState}
        submissionFormState={submissionFormState}
        initializeFormData={initializeFormData}
        isUpdatingSubmission={isUpdatingSubmission}
        checkForChangesInQuestions={checkForChangesInQuestions}
        hasCheckpointStarted={hasCheckpointStarted}
        isParticipant={isParticipant}
        canSubmitAfterDeadline={enableCheckpointSubmissionAfterDeadline}
        hasChallengeEnded={hasChallengeEnded}
        isPreview={isPreview}
        isCheckpointLocked={isCheckpointLocked}
      />
    </div>
  );

  const isSubmissionFormInMobileView = !isGtEqMd && isSubmissionFormOpen;
  const showConfirmationPopOver =
    isSubmissionFormInMobileView && showConfirmation;
  const showConfirmationModal = isGtEqMd && showConfirmation;

  function checkCanCloseSubmissionForm(onClose) {
    const isChanged = isSubmissionChanged();
    if (isChanged) {
      setShowConfirmation(true);
    } else {
      setShowConfirmation(false);
      onClose && onClose();
    }
  }

  // Show confirmation prompt if edits are made, otherwise just close
  function handleCloseSubmissionForm() {
    checkCanCloseSubmissionForm(resetStateAndCloseForm);
  }

  function handleCloseSideDrawer() {
    checkCanCloseSubmissionForm(closeSideDrawer);
  }

  function handleDiscardChanges() {
    resetStateAndCloseForm();
    setShowConfirmation(false);
  }

  const confirmationText = isCheckpointCompleted
    ? t('continue-to-edit')
    : t('save-as-draft');

  function handleContinueToEdit() {
    setShowConfirmation(false);
  }

  const confirmationAction = isCheckpointCompleted
    ? handleContinueToEdit
    : handleSaveDraft;

  const modalPopOverSubText = isCheckpointCompleted
    ? t('you-have-unsaved-changes-are-you-sure-you-want-to-leave')
    : t('save-your-submission-as-a-draft-or-exit-without-saving');

  function renderConfirmationPopover() {
    return (
      <div className="flex flex-col justify-center gap-10 px-16 pb-32 text-center font-poppins">
        <h3 className="text-20 font-semibold text-npl-text-icon-on-light-surface-primary">
          {t('there-are-unsaved-changes')}
        </h3>
        <p className="pb-24 text-16">{modalPopOverSubText}</p>
        <NPLButton
          hierarchy="accent_primary"
          buttonText={confirmationText}
          size="lg"
          onClick={confirmationAction}
        />
        <NPLButton
          hierarchy="outline"
          buttonText={t('discard-and-exit')}
          size="lg"
          onClick={handleDiscardChanges}
        />
      </div>
    );
  }

  const onCheckpointSelect = (checkpointId) => {
    resetStateAndCloseForm();
    openCheckpointDetails(checkpointId);
  };

  const onBackClick = isSubmissionFormInMobileView
    ? handleCloseSubmissionForm
    : handleCloseSideDrawer;

  return (
    <>
      <div className="bg-npl-neutral-light-solid-2">
        {/* Nav */}
        {isGtEqLg && !isPreview && (
          <ChallengeCheckpointPageHeader
            challengeTitle={challengeTitle}
            isUploading={isUploading}
            onBackClick={onBackClick}
            showCheckpointSidenav={showCheckpointSidenav}
            toggleCheckpointSidenav={toggleCheckpointSidenav}
            isPreview={isPreview}
          />
        )}
        {/* Body Section */}
        <div className="h-full-dvh lg:px-16 lg:pb-16 lg:h-[calc(100dvh-56px)] overflow-hidden">
          {/* Body White Card */}
          <div className="lg:rounded-12 bg-white-default h-full overflow-y-auto lg:overflow-hidden">
            <div className="lg:flex lg:h-full">
              <div className={classNames('relative flex-1 flex')}>
                {/* Col 1 - Checkpoint info */}
                {showContentAndDescription && (
                  <div
                    className={classNames(
                      'flex-1 overflow-y-auto px-16 lg:px-24 pt-32 pb-[134px] lg:pb-[72px]',
                      {
                        'border-r-1 border-npl-neutral-light-solid-4':
                          isSubmissionFormOpen
                      }
                    )}>
                    <ChallengeCheckpointPageCheckpointInfo
                      challengeId={challengeId}
                      communityId={communityId}
                      infiniteListProps={infiniteListProps}
                      isPreview={isPreview}
                      checkpointCount={checkpointCount}
                      selectedCheckpointData={selectedCheckpointData}
                      hasContent={hasContent}
                      isCheckpointCompleted={isCheckpointCompleted}
                      isLeaderboardEnabled={isLeaderboardEnabled}
                      isCheckpointLocked={isCheckpointLocked}
                      participantJoinedDate={participantJoinedDate}
                      isUploading={isUploading}
                      onBackClick={onBackClick}
                      onCheckpointSelect={onCheckpointSelect}
                    />
                  </div>
                )}

                {/* Col 2 - Checkpoint submission form */}
                {isSubmissionFormOpen && (
                  <div
                    className={classNames(
                      'relative flex-1 flex flex-col'
                    )}>
                    <div className="flex flex-1 flex-shrink grid-cols-1 justify-center pb-[100px] overflow-y-auto">
                      <CheckpointSubmissionForm
                        isUpdatingSubmission={isUpdatingSubmission}
                        handleSaveDraft={handleSaveDraft}
                        closeSubmissionForm={handleCloseSubmissionForm}
                        handleInputChange={handleInputChange}
                        formData={formData}
                        submissionQuestions={currentSubmissionQuestions}
                        hideContentAndDescription={
                          hideContentAndDescription
                        }
                        submissionFormState={submissionFormState}
                        communityId={communityId}
                        checkpointData={checkpointData}
                        participantId={participantId}
                        inputError={inputError}
                        isUploading={isUploading}
                        setIsUploading={setIsUploading}
                        hasCheckpointStarted={hasCheckpointStarted}
                        isPreview={isPreview}
                      />
                    </div>
                    {renderCheckpointFooter()}
                  </div>
                )}
                {!isSubmissionFormOpen && renderCheckpointFooter()}
              </div>
              {/* Checkpoints Sidenav */}
              {!isPreview && isGtEqLg ? (
                <div
                  className={classNames(
                    'transition-all duration-300 ease-in-out',
                    {
                      'w-[320px] border-l-1 border-npl-separator-alpha overflow-y-auto overflow-x-hidden':
                        showCheckpointSidenav,
                      'w-0 border-l-0': !showCheckpointSidenav
                    }
                  )}>
                  <div className="w-[320px]">
                    <div className="p-16">
                      <p className="text-heading-xs font-semibold text-dark-1b">
                        {t('overview')}
                      </p>
                    </div>
                    <div className="px-8">
                      <InfiniteList {...infiniteListProps}>
                        {(checkpoint, index) => (
                          <CheckpointPreviewCard
                            isInSidebar
                            checkpoint={checkpoint}
                            key={index}
                            hasDivider={index < lastCheckpointIndex}
                            completionStatus={checkpoint.completionStatus}
                            isCheckpointLocked={checkpoint.locked}
                            isCheckpointCompleted={checkpoint.isCompleted}
                            onClick={() => {
                              onCheckpointSelect(checkpoint._id);
                              trackGAEvent(
                                'member_challenge_checkpoint_sidenav_item_clicked',
                                {
                                  productId: challengeId,
                                  productType: 'CHALLENGE',
                                  communityId: communityId
                                }
                              );
                            }}
                            isCurrentParticipantCheckpoint={
                              checkpoint.isCurrentParticipantCheckpoint
                            }
                            participantJoinedDate={participantJoinedDate}
                            isSelected={checkpoint._id === checkpointId}
                          />
                        )}
                      </InfiniteList>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {isFeedFormOpen && (
        <FeedFormScreen
          feedFormData={feedFormData}
          setFeedFormData={setFeedFormData}
          checkpointTitle={title}
          submissionRequired={submissionRequired}
          onSubmit={async () => {
            const { error } = await submitFeedForm();

            if (error) {
              showErrorToast(error);
            }

            setIsFeedFormOpen(false);

            onCompleteFeed?.();

            closeSideDrawer();
          }}
        />
      )}
      {isSuccessScreenOpen && (
        <CheckpointSuccessScreen
          closeScreen={() => {
            setIsSuccessScreenOpen(false);
          }}
          closeCallback={() => {
            //if the feed setting is enabled, we need to show another form for feed data, so we cannot close the side drawer
            if (isFeedEnabled) {
              setIsFeedFormOpen(true);
            } else {
              handleCloseSideDrawer();
            }
          }}
          title={t('title-completed', { title })}
          listOfPoints={{
            completionPoint,
            completionResponseTimePoint,
            completion3SequentialConsecutivePoint
          }}
          completionStatus={completionStatus}
          rank={leaderboardInfo?.rank}
          pointsEarned={pointsEarned}
          autoClosingTimeout={
            isFeedEnabled && !isLeaderboardEnabled ? 3000 : 0
          } //3 seconds
          isCloseCtaShown={!isFeedEnabled}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          secondaryBtnText={confirmationText}
          primaryBtnText={t('discard-and-exit')}
          title={t('there-are-unsaved-changes')}
          subtitle={modalPopOverSubText}
          onClose={() => setShowConfirmation(false)}
          primaryBtnOnClick={handleDiscardChanges}
          secondaryBtnOnClick={confirmationAction}
        />
      )}
      {showConfirmationPopOver && (
        <PopOverModal open={showConfirmationPopOver} showCloseIcon={false}>
          {renderConfirmationPopover()}
        </PopOverModal>
      )}
    </>
  );
}

export default withComponentClassName(ChallengeCheckpointPage);
