import { FC } from 'react';

const SideSheetFooter: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <div className="c-SideSheetFooter bg-white-default border-t-1 border-npl-separator-alpha">
      {children}
    </div>
  );
};

export default SideSheetFooter;
