import { FC, useRef, useState } from 'react';

import withComponentClassName from '@/utility/withComponentClassName';

import AdaptiveVideo from '@/components/common/AdaptiveVideo';
import { ShakaVideoAssetDataType } from '@/components/common/AdaptiveVideo/types';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import NPLModalNew from '@/components/npl/NPLModalNew';

type ChallengeCoverVideoProps = {
  alt: string;
  thumbnail: string;
  videoAssets: ShakaVideoAssetDataType;
  isThumbnailStretched?: boolean;
};

const ChallengeCoverVideo: FC<ChallengeCoverVideoProps> = ({
  videoAssets
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const handleVideoModalOpen = () => {
    setIsVideoPlaying(true);
    videoRef.current.pause();
  };
  const handleVideoModalClose = () => {
    setIsVideoPlaying(false);
    videoRef.current.play();
  };

  const adaptiveVideoData = {
    mobileVideoOrientation: 'square',
    lgVideoOrientation: 'square',
    videoAssets: videoAssets
  };

  return (
    <>
      <div
        className="group aspect-h-1 aspect-w-2 md:aspect-h-9 md:aspect-w-16"
        onClick={handleVideoModalOpen}>
        {/* Video player icon */}
        <div className="absolute z-1 flex h-full w-full cursor-pointer items-center justify-center bg-black opacity-[0.15]"></div>
        <div className="absolute z-[2] flex h-full w-full cursor-pointer items-center justify-center">
          <div className="flex h-56 w-56 items-center justify-center rounded-full bg-npl-transparent-black-50 backdrop-blur-npl-styles-filter-blur">
            <Icon
              name="play-filled"
              fill="#F5F5F5"
              height={24}
              width={24}
            />
          </div>
        </div>
        {/* Thumbnail if no video is available */}
        <div className="aspect-h-1 aspect-w-2 h-full w-full cursor-pointer overflow-hidden rounded-12 bg-black md:aspect-h-9 md:aspect-w-16">
          <AdaptiveVideo
            {...adaptiveVideoData}
            isTrailerPlaying={true}
            muted={true}
            loop={true}
            isControlBarHidden={true}
            startWithLowQuality={true}
            videoRef={videoRef}
            limitedPlayingSeconds={10}
          />
        </div>
      </div>
      {isVideoPlaying && (
        <NPLModalNew onClose={handleVideoModalClose}>
          {(onClose) => (
            <NPLModalNew.Container size={768}>
              <NPLModalNew.Content className="!p-0">
                <div className="absolute top-12 right-12 z-10">
                  <NPLButton
                    hierarchy="plain"
                    leadIcon="x-close"
                    rounded
                    size="md"
                    isSecondary
                    onClick={onClose}
                  />
                </div>
              </NPLModalNew.Content>
              <AdaptiveVideo
                {...adaptiveVideoData}
                isTrailerPlaying={isVideoPlaying}
                onVideoEnd={onClose}
              />
            </NPLModalNew.Container>
          )}
        </NPLModalNew>
      )}
    </>
  );
};

export default withComponentClassName(ChallengeCoverVideo);
