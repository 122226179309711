import Auth from '@/modules/Auth';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import {
  getTrackingInformationForBE,
  trackGAEvent
} from '@/utility/analytics';
import { checkIsCommunityCheckoutRequired } from '@/utility/communityHelpers';
import {
  getFormattedStartEndDate,
  getTimezoneId
} from '@/utility/dateHelper';
import {
  checkIfIndexable,
  getAppLinkForChallenge,
  getAppLinkForChallenges,
  openInNewTab
} from '@/utility/helpers';
import { t } from '@/utility/localization';
import {
  getCommunityCheckoutRoute,
  getCommunityPageTabRoute
} from '@/utility/routesHelper';

import ViewTrackingWrapper from '@/features/SessionTracking/components/ViewTrackingWrapper';
// import SignUpPopup from '@/features/SignUpPopup';
import { SIGNUP_ORIGIN } from '@/features/SignUpPopup/constants';

import { unifiedCheckoutSignup } from '@/services/communitiesService';
import { getChallenge } from '@/services/community/CommunityChallengesService';

import { useAuthContext } from '@/contexts/AuthContext';

import {
  checkIfHtmlHasContent,
  isEditorEmpty
} from '@/components/common/Editor/utils/common';
import LoadingCircle from '@/components/common/LoadingCircle';
import PageMetaTags from '@/components/common/PageMetaTags';
import PublicPageFooter from '@/components/common/PublicPageFooter';
import {
  showErrorToast,
  showSuccessToast
} from '@/components/common/ToastContainer';
import WithPendingCommMembershipApprovalModal from '@/components/features/CommunityPage/components/home/WithPendingCommMembershipApprovalModal';
import WithWelcomeToCommunitySplashScreen from '@/components/features/CommunityPage/components/home/WithWelcomeToCommunitySplashScreen';
import { TABS_ID } from '@/components/features/CommunityPage/constants';
import GenericNavBar from '@/components/features/Navbar/GenericNavbar';

import useShallowRouterReplace from '@/hooks/router/useShallowRouterReplace';
import useCommonPublicPageData from '@/hooks/useCommonPublicPageData';
import { DISCOUNT_ENTITY_TYPE } from '@/hooks/useDiscount';
import useEntityPrice from '@/hooks/useEntityPrice';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import useLocale from '@/hooks/useLocale';
import usePixelTracking from '@/hooks/usePixelTracking';
import usePosts from '@/hooks/usePosts';
import useQueryParams from '@/hooks/useQueryParams';
import {
  getCheckoutRequestor,
  getSourceInfo,
  getUnifiedSignupMemberInfo,
  getUnifiedSignupPaymentProvider
} from '@/hooks/utils/communityMembersUtils';

import NotFound from '@/pages/404.page';
import { CheckoutTypes } from '@/pages/checkout-global/consts';
import PortalBanner from '@/pages/portal/components/PortalBanner/Portalbanner';

import BlurredBackground from '../EventPublicPage/components/BlurredBackground/BlurredBackground';
import MembershipBenefits from '../ResourcePage/components/MembershipBenefits';
import useLandingPageUserBehaviorTracking from '../SessionTracking/hooks/useLandingPageUserBehaviorTracking';
import CompleteYourProfileModalWrapper from '../User/CompleteYourProfileModal/CompleteYourProfileModalWrapper';
import Faiq from '../faiq/components/Faiq';
import AboutChallenge from './components/AboutChallenge';
import ChallengeCardContent from './components/ChallengeCardContent';
import CheckpointPreview from './components/CheckpointPreview';
import HeroBanner from './components/HeroBanner';
import SuccessChallengeModal from './components/SuccessChallengeModal';
import useChallengeLandingPageAnalytics from './hooks/useChallengesLandingPageAnalytics';
import {
  getChallengeDurationByCheckpointDuration,
  getChallengeManagementUrl
} from './utils';

// const AISalesAgent = dynamic(
//   () => import('./components/AISalesAgent/AISalesAgent')
// );
const BecomeAnAffiliateCard = dynamic(
  () => import('../Affiliates/components/BecomeAnAffiliateCard')
);
const CommunitySignupModal = dynamic(
  () => import('@/features/CommunitySignupModal/CommunitySignupModal'),
  {
    ssr: false
  }
);
const YoureAnAffiliateOfTheProductCard = dynamic(
  () => import('../Affiliates/components/YoureAnAffiliateOfTheProductCard')
);
const BecomeAnAffiliateModal = dynamic(
  () => import('../Affiliates/components/BecomeAnAffiliateModal')
);

const ChallengeLandingPage = (props) => {
  const { currentLocale } = useLocale();

  const [showSuccessChallengeModal, setShowSuccessChallengeModal] =
    useState(false);
  const [isJoiningChallenge, setIsJoiningChallenge] = useState(false);
  const [fetchingChallengeDetails, setFetchingChallengeDetails] =
    useState(true);
  const [challengeFetchedDetails, setChallengeFetchedDetails] =
    useState(null);
  const { templateData, templateContentType } = props?.pageInfo || {};
  const userCommunitiesData = props?.userCommunitiesData;

  const {
    isCommunityAdmin,
    isPendingApproval,
    isCommunityMember,
    isCommunityAffiliate,
    isSignUpModalOpened,
    openSignUpModal,
    isSignupModalOnlyForCommunity,
    isSignupModalToBecomeAffiliate,
    onSignUpPopupClose,
    loadingUserRoles,
    isUserCommunityRoleFetched,
    refetchUserRoleInCommunity,
    // affilaite program
    joinAsAffiliateProps,
    setAffiliateInfo
  } = userCommunitiesData || {};
  const {
    communityInfo,
    challengeInfo,
    communityLink: communitySlug,
    challengeSlug
  } = templateData || {};
  const communityObjectId = communityInfo._id;

  const { trackOnPixel } = usePixelTracking({
    communityId: communityObjectId,
    trackingPixels: communityInfo?.trackingPixels,
    postInitProcess: {
      trackPageView: true
    }
  });

  const { checkpointCount } = challengeInfo?.challengeSpecific || {};

  const { instructions, rewards, instructionsHTML, rewardsHTML } =
    challengeInfo?.additionalSettings || {};
  const [instructionsContent, setInstructionsContent] = useState(null);
  const [rewardsContent, setRewardsContent] = useState(null);
  // Keep track if user enrolled on the UI, so we don't have to fetch whole community API again till refresh
  const [hasEnrolledToCommunity, setHasEnrolledToCommunity] =
    useState(false);

  useEffect(() => {
    setInstructionsContent(instructions);
    setRewardsContent(rewards);
  }, [instructions, rewards]);

  const { trackJoinChallengeBtnClick, trackReadMoreBtnClick } =
    useChallengeLandingPageAnalytics(communityInfo, challengeInfo);

  // User behavior tracking
  useLandingPageUserBehaviorTracking({
    communityCode: communityInfo?.code,
    communityObjectId: communityInfo?._id,
    entityObjectId: challengeInfo?._id,
    entityType: 'CHALLENGE'
  });

  const viewTrackingWrapperCommonProps = {
    communityId: communityInfo?._id,
    communityCode: communityInfo?.code,
    entityId: challengeInfo?._id,
    entityType: 'CHALLENGE'
  };

  const { aiAssistantInfo } = communityInfo;
  const startDate = useMemo(
    () => new Date(challengeInfo?.startTime),
    [challengeInfo?.startTime]
  );
  const endDate = useMemo(
    () => new Date(challengeInfo?.endTime),
    [challengeInfo?.endTime]
  );

  const router = useRouter();
  const pathname = usePathname();
  const { replaceRoute } = useShallowRouterReplace();

  const { currentRouterQuery } = useQueryParams();

  const { withChallengeSuccessModal } = currentRouterQuery;

  const { isLoggedIn, user } = useAuthContext();

  const { discountedCodeData } = useEntityPrice({
    communityCode: communityInfo?.code,
    entityId: challengeInfo?._id,
    entityType: DISCOUNT_ENTITY_TYPE.CHALLENGE
  });

  // Affiliate Program Data start
  const isAffiliateProduct = Boolean(
    challengeInfo?.affiliateInfo?.isAffiliateProduct ||
      challengeFetchedDetails?.affiliateInfo?.isAffiliateProduct
  );

  const {
    // card
    showJoinAsAffiliateCard,

    // modal
    showJoinAsAffiliateModal,
    onOpenJoinAsAffiliateModal,
    onCloseJoinAsAffiliateModal,

    // join as affiliate fn
    isSubmittingJoinAsAffiliate,
    onJoinAsAffiliate
    // joinAsAffiliateError
  } = joinAsAffiliateProps;

  useEffect(() => {
    const affiliateInfo =
      challengeFetchedDetails?.affiliateInfo ||
      challengeInfo?.affiliateInfo;

    if (affiliateInfo) {
      setAffiliateInfo(affiliateInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    challengeInfo?.affiliateInfo,
    challengeFetchedDetails?.affiliateInfo
  ]);
  // Affiliate Program Data end

  useEffect(() => {
    if (challengeInfo?._id)
      trackGAEvent('track_challenge_public_page_view', {
        productType: 'CHALLENGE',
        productId: challengeInfo?._id,
        challengeId: challengeInfo?._id,
        communityCode: communityInfo?.code,
        communityId: communityInfo?._id
      });
  }, [challengeInfo?._id, communityInfo?._id, communityInfo?.code]);

  const appLink = getAppLinkForChallenge(
    communityInfo,
    challengeInfo,
    user
      ? {
          email: user.email
        }
      : null
  );
  const communityPageChallengeTabRoute = getCommunityPageTabRoute({
    communitySlug: communityInfo?.slug || '',
    tabId: TABS_ID.CHALLENGES,
    fullUrl: true
  });
  const appLinkChallenges = getAppLinkForChallenges(
    communityInfo,
    communityPageChallengeTabRoute,
    user
      ? {
          email: user.email
        }
      : null
  );

  const fetchLatestChallengeDetails = async () => {
    setFetchingChallengeDetails(true);

    const { data, error } = await getChallenge({
      challengeId: challengeInfo?._id,
      communityId: communityInfo?._id
    });

    if (error) {
      console.error('Error fetching challenge', error);
      setFetchingChallengeDetails(false);
      return;
    }

    setChallengeFetchedDetails(data?.data);

    setFetchingChallengeDetails(false);
  };

  const handleGoToManageChallenge = () => {
    router.push(
      getChallengeManagementUrl({
        id: challengeInfo?._id,
        activeCommunityId: communityInfo?._id
      })
    );
  };

  const {
    discountCodeFromQueryParams,
    priceTagText,
    pricingData,
    affiliateCode
  } = useCommonPublicPageData({ communityInfo });

  const isCommunityCheckoutRequired =
    !isCommunityMember && checkIsCommunityCheckoutRequired(communityInfo);

  const isChallengeCheckoutRequired = challengeInfo?.access === 'paid';
  const { canJoinAfterStart } = challengeInfo || {};

  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';

  const hasChallengeStarted = useMemo(
    () => startDate <= new Date(),
    [startDate]
  );

  const hasChallengeEnded = useMemo(
    () => endDate <= new Date(),
    [endDate]
  );

  const showPreviewBanner =
    isCommunityAdmin && challengeInfo?.status === 'draft';

  const isPreview = challengeInfo?.status !== 'published';

  // const handleGoToCheckout = (challengeUnavailable = false) => {
  const handleGoToCheckout = (params = {}) => {
    const { checkoutWithoutChallenge, isForAffiliateSignup } = params;
    const checkoutRoute = getCommunityCheckoutRoute({
      communityId: communityInfo?._id,
      communityCode: communityInfo?.code,
      requestor:
        currentRouterQuery?.requestor ||
        getCheckoutRequestor({ communityInfo }),
      sourceInfo: checkoutWithoutChallenge
        ? {}
        : getSourceInfo({
            signUpOrigin: SIGNUP_ORIGIN.CHALLENGE_PAGE,
            challengeInfo
          }),
      entityDiscountCode: discountCodeFromQueryParams,
      affiliateCode,
      withJoinAsAffiliateCb: isForAffiliateSignup,
      recipientObjectId: currentRouterQuery?.recipientObjectId
    });

    router.push(checkoutRoute);
  };

  const handleJoinFreeChallenge = async (
    withCommunitySignup = false,
    isPending
  ) => {
    setIsJoiningChallenge(true);

    const userData = Auth.getUserDataSession();

    let items = [];

    if (withCommunitySignup) {
      const communitySignupItem = {
        type: CheckoutTypes.SUBSCRIPTION
      };
      items.push(communitySignupItem);
    }

    const challengeSignUpitem = {
      type: CheckoutTypes.CHALLENGE,
      entityId: challengeInfo?._id
    };

    items.push(challengeSignUpitem);

    let payload = {
      communityCode: communityInfo?.code,
      timezone: getTimezoneId(),
      trackingData: getTrackingInformationForBE(),
      requestor: getCheckoutRequestor({ communityInfo }),
      memberInfo: getUnifiedSignupMemberInfo(userData),
      paymentProvider: getUnifiedSignupPaymentProvider(communityInfo),
      items
    };

    if (affiliateCode) {
      payload.affiliateCode = affiliateCode;
    }

    const resp = await unifiedCheckoutSignup(payload);

    if (resp.error) {
      showErrorToast(resp.error);
      setIsJoiningChallenge(false);
      return;
    }
    if (
      !resp.error &&
      resp?.data?.entityStatus.challengeJoined &&
      !isPending &&
      (withCommunitySignup
        ? resp?.data?.enrollmentStatus?.memberEnrolled
        : true) // check for membership enrolled only if withCommunitySignup is true
    ) {
      showSuccessToast(t('successfully-joined-community'));
      onSignUpPopupClose();
      setShowSuccessChallengeModal(true);
      fetchLatestChallengeDetails();
    }
    // refetch user communities after joining free community
    refetchUserRoleInCommunity();
    setIsJoiningChallenge(false);
  };

  const handlePostFreeCommunitySignup = (isPending) => {
    if (isSignupModalOnlyForCommunity) {
      refetchUserRoleInCommunity();

      // if signup was for affiliate, redirect to affiliate products page
      if (isSignupModalToBecomeAffiliate) {
        onOpenJoinAsAffiliateModal();
      }

      // Do nothing if signup was only for community
      return;
    }

    const isChallengeJoinable = !(
      (hasChallengeStarted && !canJoinAfterStart) ||
      hasChallengeEnded
    );
    // Check if challenge is joinable after signing up for free comm
    // then decide if joining free/paid challenge
    if (isChallengeJoinable) {
      if (isChallengeCheckoutRequired) {
        handleGoToCheckout();
        return;
      }

      handleJoinFreeChallenge(false, isPending);
    }
    // If challenge not joinable, just mutateUserComms as user have joined community
    // And show dat success toast
    else {
      refetchUserRoleInCommunity();
      showSuccessToast(t('you-are-now-a-member'));
    }
  };

  // Only if challenge has started/ended, prompt user to join as member
  const handleJoinAsMember = async (
    params = {
      isForAffiliateSignup: false
    }
  ) => {
    const { isForAffiliateSignup } = params;

    trackGAEvent('challenge_landing_page_join_as_member_btn_clicked', {
      communityId: communityInfo?._id,
      productId: challengeInfo?._id,
      productType: 'CHALLENGE'
    });
    setIsJoiningChallenge(true);
    trackJoinChallengeBtnClick(challengeInfo?.access);
    const userData = Auth.getUserDataSession();

    // Is paid community
    if (isCommunityCheckoutRequired) {
      handleGoToCheckout({
        checkoutWithoutChallenge: true,
        isForAffiliateSignup: Boolean(isForAffiliateSignup)
      });
      return;
    }

    if (isLoggedIn) {
      const withCommunitySignup = isCommunityMember ? false : true;

      // Is free community
      let payload = {
        communityCode: communityInfo?.code,
        timezone: getTimezoneId(),
        trackingData: getTrackingInformationForBE(),
        requestor: getCheckoutRequestor({ communityInfo }),
        memberInfo: getUnifiedSignupMemberInfo(userData),
        paymentProvider: getUnifiedSignupPaymentProvider(communityInfo),
        items: [
          {
            type: CheckoutTypes.SUBSCRIPTION
          }
        ]
      };

      if (affiliateCode) {
        payload.affiliateCode = affiliateCode;
      }

      const resp = await unifiedCheckoutSignup(payload);

      if (resp.error) {
        showErrorToast(resp.error);
        showErrorToast('Error joining community');
        setIsJoiningChallenge(false);
        return;
      }

      if (
        !resp.error &&
        (withCommunitySignup
          ? resp?.data?.enrollmentStatus?.memberEnrolled
          : true) // check for membership elrolled only if withCommunitySignup is true
      ) {
        setIsJoiningChallenge(false);
        setHasEnrolledToCommunity(true);
        refetchUserRoleInCommunity();
        showSuccessToast(t('you-are-now-a-member'));

        if (isForAffiliateSignup) {
          onOpenJoinAsAffiliateModal();
        }
      }
      return;
    }

    // not a member
    // const onlySignupForCommunity = true;
    // // openSignUpModal(onlySignupForCommunity);
    openSignUpModal({
      onlySignupToCommunity: true,
      isSignupModalToBecomeAffiliate: Boolean(isForAffiliateSignup)
    });
    setIsJoiningChallenge(false);
  };

  const trackJoinChallengeClick = () => {
    const eventName = 'nasio_challenge_landing_page_join_btn_clicked';
    trackOnPixel(eventName);
    trackGAEvent(eventName, {
      communityId: communityInfo?._id,
      productId: challengeInfo?._id,
      productType: 'CHALLENGE'
    });
  };

  const initJoinChallenge = () => {
    trackJoinChallengeClick();
    setIsJoiningChallenge(true);
    if (isCommunityCheckoutRequired || isChallengeCheckoutRequired) {
      const checkoutWithoutChallenge =
        (hasChallengeStarted && !canJoinAfterStart) || hasChallengeEnded;
      handleGoToCheckout({ checkoutWithoutChallenge });
      return;
    }

    if (isLoggedIn) {
      const withCommunitySignup = isCommunityMember ? false : true;
      handleJoinFreeChallenge(withCommunitySignup);
      return;
    }

    // not a member
    openSignUpModal();
    setIsJoiningChallenge(false);
  };

  const generateAIctaButtonProps = () => {
    const isParticipant = challengeFetchedDetails?.joined;
    if (isCommunityAdmin) {
      return {
        onClick: handleGoToManageChallenge,
        buttonText: t('manage-challenge')
      };
    }
    if (isParticipant) {
      return {
        onClick: () => openInNewTab(appLink),
        buttonText: t('open-in-app')
      };
    }

    if (isPendingApproval) {
      return {
        disabled: true,
        buttonText: t('pending-approval')
      };
    }

    if ((hasChallengeStarted && !canJoinAfterStart) || hasChallengeEnded) {
      if (isCommunityMember || hasEnrolledToCommunity) {
        return {
          buttonText: t('go-to-community'),
          onClick: () => openInNewTab(appLinkChallenges)
        };
      }

      return {
        buttonText: t('join-community'),
        onClick: handleJoinAsMember
      };
    }

    return {
      buttonText: t('join-challenge-1'),
      onClick: initJoinChallenge,
      loading: isJoiningChallenge
    };
  };

  const renderDaysDateRange = () => {
    if (challengeInfo?.durationInDays > 0) {
      return (
        <div className="mb-16 text-heading-xs font-medium text-npl-text-icon-on-dark-secondary">
          {getChallengeDurationByCheckpointDuration(
            challengeInfo?.challengeSpecific?.checkpointDurationInDays,
            challengeInfo?.durationInDays
          )}
          <span className="px-8">•</span>
          {getFormattedStartEndDate({
            startDate,
            endDate,
            locale: currentLocale
          })}
        </div>
      );
    } else {
      return (
        <div className="mb-16 text-heading-xs font-medium text-npl-text-icon-on-dark-secondary">
          {t('dates-not-set')}
        </div>
      );
    }
  };

  // part of MembershipBenefits
  const ChatGroupIcons = {
    whatsapp: {
      iconName: 'whatsapp-neutral',
      chatGroupName: 'WhatsApp group'
    },
    telegram: {
      iconName: 'telegram-neutral',
      chatGroupName: 'Telegram group'
    },
    facebook: {
      iconName: 'facebook-neutral',
      chatGroupName: 'Facebook group'
    },
    discord: {
      iconName: 'discord-neutral',
      chatGroupName: 'Discord server'
    },
    slack: {
      iconName: 'slack-neutral',
      chatGroupName: 'Slack channel'
    },
    linkedin: {
      iconName: 'linkedin-neutral',
      chatGroupName: 'LinkedIn group'
    }
  };

  // part of MembershipBenefits
  const { allPosts, fetchPosts } = usePosts({
    communityId: communityInfo?._id
  });

  // part of MembershipBenefits
  const getMembershipBenefits = () => {
    const membershipBenefits = [
      {
        name: 'users-01',
        path: 'common',
        title: t('community-access')
      },
      {
        name: 'announcement-03',
        path: 'common',
        title: t('new-updates')
      }
    ];
    if (allPosts.length) {
      membershipBenefits.push({
        name: 'layout-alt-03',
        path: 'common',
        title: `${allPosts.length} ${
          allPosts.length > 1 ? t('posts') : t('post')
        }`
      });
    }

    membershipBenefits.push({
      name: 'video-courses',
      path: 'common',
      title: t('exclusive-content')
    });
    const upcomingEventsLength = communityInfo.upcomingEvents?.length;
    if (upcomingEventsLength > 0) {
      membershipBenefits.push({
        name: 'calendar',
        path: 'common',
        title: `${upcomingEventsLength} ${
          upcomingEventsLength > 1 ? t('events') : t('event')
        }`
      });
    }
    if (communityInfo.bots?.length > 0) {
      const platform = communityInfo?.bots[0]?.type.toLowerCase();
      membershipBenefits.push({
        name: ChatGroupIcons[platform]?.iconName,
        path: 'social',
        title: ChatGroupIcons[platform]?.chatGroupName
      });
    }

    return membershipBenefits;
  };

  // TODO: to refactor after BE changes and Memberbenefits component merged
  const membershipBenefits = getMembershipBenefits();

  useEffect(() => {
    fetchLatestChallengeDetails();
  }, []);

  useEffect(() => {
    fetchPosts();
  }, []);

  // Show challenge success modal if withChallengeSuccessModal is true in query params
  useEffect(() => {
    if (withChallengeSuccessModal) {
      setShowSuccessChallengeModal(true);

      // delete withSuccessJoinedModal from router query
      const updatedQuery = { ...currentRouterQuery };
      delete updatedQuery.withChallengeSuccessModal;

      replaceRoute(updatedQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withChallengeSuccessModal]);

  const metaTagProps = {
    title: challengeInfo?.title,
    description: `${getChallengeDurationByCheckpointDuration(
      challengeInfo?.challengeSpecific?.checkpointDurationInDays,
      challengeInfo?.durationInDays
    )} • Challenge by ${challengeInfo?.host?.name}`,
    imageUrl: challengeInfo?.cover,
    url: `${origin}${pathname}`,
    isIndexable:
      checkIfIndexable(communityInfo) && checkIfIndexable(challengeInfo)
  };

  const [ctaButtonRef, isIntersectingCta] = useIntersectionObserver({
    threshold: [0.1] // Trigger when 10% of the element is visible
  });

  // Handle preview pages. Should not show unpublished public pages to NON CM users
  if (isPreview) {
    if (loadingUserRoles || !isUserCommunityRoleFetched) {
      return <LoadingCircle />;
    } else if (!isCommunityAdmin) {
      return <NotFound />;
    }
  }

  const isChallengeJoinable =
    !challengeInfo?.isRegistrationClosed &&
    !isCommunityAdmin &&
    !isPendingApproval &&
    !challengeFetchedDetails?.joined &&
    ((hasChallengeStarted && canJoinAfterStart) || !hasChallengeStarted);

  const showYouAreAnAffiliateCard =
    isCommunityAffiliate && isAffiliateProduct;

  return (
    <div className="c-ChallengeLandingPage relative">
      <PageMetaTags {...metaTagProps} />
      {/* Navbar */}
      {!loadingUserRoles && !isLoggedIn && (
        <GenericNavBar
          communityInfo={communityInfo}
          openSignUpModal={openSignUpModal}
          showEntityCTAButton={isChallengeJoinable && !isIntersectingCta}
        />
      )}
      {showPreviewBanner && (
        <PortalBanner
          showAlertIcon={true}
          zIndex={1}
          iconColor={'#946800'}
          textColor={'text-npl-yellow-light-solid-11'}
          text={t(
            'this-is-a-preview-publish-the-challenge-to-view-the-page-live'
          )}
          isPublicPage
        />
      )}

      <div className="relative bg-black/80 px-24 pb-40 pt-16 md:mb-40">
        <BlurredBackground
          bannerImg={challengeInfo?.cover}
          alt="challenge banner image"
          opacityValue="opacity-100"
          customClassName="absolute"
          blurValue="blur-md"
        />

        <div ref={ctaButtonRef}>
          <div className="flex justify-center">
            <HeroBanner
              communityInfo={communityInfo}
              challengeInfo={challengeInfo}
              isCommunityAdmin={isCommunityAdmin}
              isPendingApproval={isPendingApproval}
              isFetchingChallengeDetails={fetchingChallengeDetails}
              hasChallengeEnded={hasChallengeEnded}
              hasChallengeStarted={hasChallengeStarted}
              isParticipant={challengeFetchedDetails?.joined}
              handleGoToManageChallenge={handleGoToManageChallenge}
              renderDaysDateRange={renderDaysDateRange}
              initJoinChallenge={initJoinChallenge}
              isCommunityMember={isCommunityMember}
              hasEnrolledToCommunity={hasEnrolledToCommunity}
              handleJoinAsMember={handleJoinAsMember}
              isJoiningChallenge={isJoiningChallenge}
              discountedCodeData={discountedCodeData}
              priceDetails={
                challengeFetchedDetails?.priceDetails ||
                challengeInfo?.priceDetails
              }
              isLoggedIn={isLoggedIn}
            />
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="mx-auto w-full max-w-[928px] bg-white-default px-24">
        {/* About */}

        <ViewTrackingWrapper
          name="about-challenge"
          {...viewTrackingWrapperCommonProps}>
          <AboutChallenge
            hostProfileImage={challengeInfo?.host?.profileImage}
            hostName={challengeInfo?.host?.name}
            description={challengeInfo?.description}
            descriptionHTML={challengeInfo?.descriptionHTML}
          />
        </ViewTrackingWrapper>

        {/* Checkpoints Preview */}
        {checkpointCount > 0 && (
          <ViewTrackingWrapper
            name="checkpoint-preview"
            {...viewTrackingWrapperCommonProps}>
            <CheckpointPreview
              challengeTitle={challengeInfo?.title}
              checkpointCount={checkpointCount}
              checkpointsBrief={challengeInfo?.checkpointsBrief}
              communitySlug={communitySlug}
              challengeSlug={challengeSlug}
              participantCount={challengeInfo?.participantCount}
              participantProfilePics={
                challengeInfo?.participantProfilePics
              }
              challengeId={challengeInfo?._id}
              communityId={communityInfo?._id}
              communityInfo={communityInfo}
              initJoinChallenge={initJoinChallenge}
              handleJoinAsMember={handleJoinAsMember}
              isCommunityJoinable={
                isChallengeJoinable &&
                (!hasChallengeStarted ||
                  (hasChallengeStarted && !canJoinAfterStart)) &&
                !isCommunityMember &&
                !hasEnrolledToCommunity &&
                !isPendingApproval
              }
              isChallengeJoinable={isChallengeJoinable}
            />
          </ViewTrackingWrapper>
        )}

        {/* {aiAssistantInfo?.isEnabledForChallenges && (
          <ViewTrackingWrapper
            name="faiq"
            {...viewTrackingWrapperCommonProps}>
            <AISalesAgent
              communityId={communityInfo._id}
              productId={challengeInfo._id}
              productName={challengeInfo.title}
              productType={'CHALLENGE'}
              learnerId={user?.learner?._id}
              AIctaButtonProps={generateAIctaButtonProps()}
              communityTitle={communityInfo.title}
              communityThumbnail={communityInfo?.profileImage}
            />
          </ViewTrackingWrapper>
        )} */}

        {/* Challenge Instructions */}
        {(!isEditorEmpty(instructionsContent) ||
          checkIfHtmlHasContent(instructionsHTML)) && (
          <ViewTrackingWrapper
            name="challenge-instructions"
            {...viewTrackingWrapperCommonProps}>
            <ChallengeCardContent
              icon="message-text-square-02"
              title={t('instructions')}
              content={instructionsContent}
              contentHTML={instructionsHTML}
              trackReadMoreBtnClick={trackReadMoreBtnClick}
              type="instructions"
            />
          </ViewTrackingWrapper>
        )}

        {/* Challenge Rewards */}
        {(!isEditorEmpty(rewardsContent) ||
          checkIfHtmlHasContent(rewardsHTML)) && (
          <ViewTrackingWrapper
            name="challenge-rewards"
            {...viewTrackingWrapperCommonProps}>
            <ChallengeCardContent
              icon="gift-01"
              title={t('rewards')}
              content={rewardsContent}
              contentHTML={rewardsHTML}
              trackReadMoreBtnClick={trackReadMoreBtnClick}
              type="rewards"
            />
          </ViewTrackingWrapper>
        )}
      </div>

      {/* Affiliate */}
      {showJoinAsAffiliateCard && (
        <div className="affiliate-card-section mx-24 my-32 md:mx-auto md:my-40 md:max-w-[880px]">
          <BecomeAnAffiliateCard
            isCommunityMember={isCommunityMember}
            onClick={onOpenJoinAsAffiliateModal}
            communityId={communityObjectId}
            entityId={challengeInfo._id}
            entityType="CHALLENGE"
            affiliateCode={currentRouterQuery.affiliateCode}
          />
        </div>
      )}

      {showYouAreAnAffiliateCard && (
        <div className="affiliate-card-section mx-24 my-32 md:mx-auto md:my-40 md:max-w-[880px]">
          <YoureAnAffiliateOfTheProductCard />
        </div>
      )}

      {aiAssistantInfo?.isEnabledForChallenges && (
        <ViewTrackingWrapper
          name="faiq"
          {...viewTrackingWrapperCommonProps}>
          <Faiq
            bgCoverImgSrc={challengeInfo?.cover}
            communityId={communityInfo._id}
            cmProfileImageSrc={challengeInfo?.host?.profileImage}
            productId={challengeInfo._id}
            productName={challengeInfo.title}
            productType="CHALLENGE"
            cmName={challengeInfo?.host?.name}
            ctaProps={generateAIctaButtonProps()}
          />
        </ViewTrackingWrapper>
      )}

      {/* Membership Benefits */}
      {!isCommunityMember && (
        <MembershipBenefits
          parentThumbnail={challengeInfo?.cover}
          membershipBenefits={membershipBenefits}
          isFree={challengeInfo?.access === 'free'}
        />
      )}
      {/* Footer */}
      <PublicPageFooter
        templateContentType={templateContentType}
        isCommunityAdmin={isCommunityAdmin}
        communitySlug={communityInfo?.slug}
        ctaButtonText={t('create-your-own-challenge')}
      />

      {/* Modals */}
      <CommunitySignupModal
        open={isSignUpModalOpened}
        onClose={onSignUpPopupClose}
        communityInfo={{ ...communityInfo, pricingData }}
        signUpOriginProps={
          isSignupModalOnlyForCommunity
            ? {}
            : {
                isPaid: challengeInfo?.access === 'paid',
                signUpOrigin: SIGNUP_ORIGIN.CHALLENGE_PAGE,
                challengeInfo: challengeInfo
              }
        }
        priceTagText={priceTagText}
        discountCodeFromQueryParams={discountCodeFromQueryParams}
        newMemberSignupCallback={handlePostFreeCommunitySignup}
        existingMemberLoginCallback={handlePostFreeCommunitySignup}
        isSignupModalToBecomeAffiliate={isSignupModalToBecomeAffiliate}
      />

      {showSuccessChallengeModal && (
        <SuccessChallengeModal
          open={showSuccessChallengeModal}
          onClose={() => setShowSuccessChallengeModal(false)}
          communityInfo={communityInfo}
          challengeInfo={challengeInfo}
          renderDaysDateRange={renderDaysDateRange}
        />
      )}

      <WithPendingCommMembershipApprovalModal />
      <WithWelcomeToCommunitySplashScreen communityInfo={communityInfo} />
      {!currentRouterQuery?.showSignupSuccessPrompt &&
        !currentRouterQuery?.showMembershipPendingApproval &&
        !showSuccessChallengeModal &&
        isPendingApproval && <CompleteYourProfileModalWrapper />}

      {showJoinAsAffiliateModal && (
        <BecomeAnAffiliateModal
          onClose={onCloseJoinAsAffiliateModal}
          isLoggedIn={isLoggedIn}
          userProfileImgSrc={user?.learner?.profileImage}
          communityId={communityObjectId}
          communityTitle={communityInfo?.title}
          communityProfileImgSrc={communityInfo?.profileImage}
          isCommunityMember={isCommunityMember}
          onJoinCommunity={() =>
            handleJoinAsMember({
              isForAffiliateSignup: true
            })
          }
          onJoinAsAffiliate={onJoinAsAffiliate}
          isSubmittingJoinAsAffiliate={isSubmittingJoinAsAffiliate}
        />
      )}
    </div>
  );
};

export default ChallengeLandingPage;
