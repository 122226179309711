import classNames from 'classnames';
import { ForwardRefRenderFunction, forwardRef } from 'react';

import type { NPLToggleType } from './types';
import {
  getBoxClassNames,
  getCircleElementClassNames,
  getSizeClassNames
} from './utils';

type NPLToggleProps = NPLToggleType;

const NPLToggle: ForwardRefRenderFunction<
  HTMLInputElement,
  NPLToggleProps
> = (
  {
    size = 'sm',
    disabled,
    checked,
    defaultChecked,
    onChange,
    ...nativeInputProps
  },
  ref
) => {
  if (checked !== undefined && defaultChecked !== undefined) {
    throw new Error(
      'NPLToggle: Cannot use both checked and defaultChecked props simultaneously. Use either checked for controlled component or defaultChecked for uncontrolled component.'
    );
  }

  return (
    <label
      className={classNames(
        'relative inline-flex cursor-pointer items-center',
        getSizeClassNames(size)
      )}>
      <input
        {...nativeInputProps}
        defaultChecked={defaultChecked}
        checked={checked}
        type="checkbox"
        className="peer sr-only"
        disabled={disabled}
        onChange={onChange}
        ref={ref}
      />
      <div className={getBoxClassNames(size)}>
        <div
          className={getCircleElementClassNames(
            size,
            disabled,
            checked
          )}></div>
      </div>
    </label>
  );
};

/**
 * Props for the NPLToggle component
 * @extends {Omit<HTMLAttributes<HTMLInputElement>, 'checked' | 'size' | 'disabled' | 'onChange'>}
 * @property {boolean} [checked] - Controls the checked state of the toggle
 * @property {boolean} [defaultChecked] - Sets the initial checked state on the first rendering
 * @property {boolean} [disabled] - Whether the toggle is disabled/non-interactive
 * @property {ChangeEventHandler<HTMLInputElement>} [onChange] - Handler called when toggle state changes
 * @property {ToggleSizeType} [size] - Size variant of the toggle ('xs' | 'sm' | 'md')
 */
export default forwardRef(NPLToggle);
