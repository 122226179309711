import { ImageUseCase } from '@/utility/constants';
import { t } from '@/utility/localization';

import DocumentUploader from '@/components/common/DocumentUploader';
import ImageUploader from '@/components/common/ImageUploader/ImageUploader';
import LinkAwareText from '@/components/common/LinkAwareText';
import { generateImagePath } from '@/components/common/ProgressiveImage';
import { ReadMore } from '@/components/common/ReadMore/ReadMore';
import VideoUploader from '@/components/common/VideoUploader/VideoUploader';
import NPLButton from '@/components/npl/NPLButton';
import NPLInput from '@/components/npl/NPLInput/NPLInput';

import {
  CHECKPOINT_QUESTION_TYPE,
  SUBMISSION_FORM_STATES
} from '@/pages/portal/challenges/constants';

function CheckpointSubmissionForm({
  submissionQuestions,
  handleInputChange,
  formData,
  submissionFormState,
  communityId,
  checkpointData,
  participantId,
  isUpdatingSubmission,
  handleSaveDraft,
  closeSubmissionForm,
  inputError,
  isUploading,
  setIsUploading,
  hasCheckpointStarted,
  isPreview
}) {
  const renderFormInputFromType = (type, index) => {
    const disableInput =
      submissionFormState === SUBMISSION_FORM_STATES.VIEWING || isPreview;
    switch (type) {
      case CHECKPOINT_QUESTION_TYPE.IMAGE:
        return (
          <ImageUploader
            uploadHint={t('image-size-should-be-less-than-10-mb')}
            useCase={ImageUseCase.ChallengeSubmission}
            value={formData[index]?.answer?.answerFile}
            onInput={(img, fullImgData) => {
              handleInputChange({
                index,
                answer: {
                  answerFile: generateImagePath({ src: img }),
                  answerFileSizeInKB: fullImgData?.size / 1024
                },
                type: CHECKPOINT_QUESTION_TYPE.IMAGE
              });
            }}
            onCancel={() => {
              handleInputChange({
                index,
                answer: null,
                type: CHECKPOINT_QUESTION_TYPE.IMAGE
              });
            }}
            disabled={disableInput}
          />
        );
      case CHECKPOINT_QUESTION_TYPE.TEXT:
        // If viewing submission, should show full answer
        if (disableInput && !isPreview) {
          return (
            <div className="mb-6 w-full rounded-12 bg-grey-f6 p-12 text-body-sm text-npl-text-icon-on-light-surface-tertiary">
              {formData[index]?.answer?.split('\n').map((text) => (
                <p key={text}>
                  <ReadMore
                    maxHeight={84}
                    buttonStyling="text-npl-text-icon-on-light-surface-tertiary text-label-sm font-semibold underline"
                    className="whitespace-pre-line break-words font-normal">
                    <LinkAwareText text={text} />
                  </ReadMore>
                </p>
              ))}
            </div>
          );
        } else {
          return (
            <NPLInput
              value={formData[index]?.answer}
              onInput={(e) =>
                handleInputChange({
                  index,
                  answer: e.target.value,
                  type: CHECKPOINT_QUESTION_TYPE.TEXT
                })
              }
              disabled={isPreview}
            />
          );
        }
      case CHECKPOINT_QUESTION_TYPE.VIDEO:
        return (
          <VideoUploader
            communityId={communityId}
            entityId={checkpointData?.programObjectId}
            entityType="challengeCheckPointSubmission"
            otherBodyParamsForVideoUpload={{
              participantId: participantId,
              checkpointId: checkpointData?._id
            }}
            value={formData[index]?.answer?.answerFile}
            onUpload={() => {
              setIsUploading(true);
            }}
            hasThumbnailUpload
            onUploaded={(videoData) => {
              setIsUploading(false);
              handleInputChange({
                index,
                answer: {
                  answerFile: videoData.videoLink,
                  answerFileSizeInKB: videoData?.fileSize
                    ? videoData.fileSize / 1024
                    : 0,
                  answerThumbnail: videoData?.videoThumbnailLink
                },
                type: CHECKPOINT_QUESTION_TYPE.VIDEO
              });
            }}
            onCancel={() => {
              handleInputChange({
                index,
                answer: null,
                type: CHECKPOINT_QUESTION_TYPE.VIDEO
              });
            }}
            disabled={disableInput}
            isCompressionNeeded={false}
          />
        );
      case CHECKPOINT_QUESTION_TYPE.DOCUMENT:
        return (
          <DocumentUploader
            disabled={disableInput}
            uploadProps={{
              entityId: checkpointData?.programObjectId,
              entityType: 'challengeCheckPointSubmission'
            }}
            value={formData[index]?.answer?.answerFile}
            onCancel={() => {
              handleInputChange({
                index,
                answer: null,
                type: CHECKPOINT_QUESTION_TYPE.DOCUMENT
              });
            }}
            onUploaded={(documentData) => {
              handleInputChange({
                index,
                answer: {
                  answerFile: documentData.documentLink,
                  answerFileSizeInKB: documentData?.fileSize
                    ? documentData.fileSize / 1024
                    : 0
                },
                type: CHECKPOINT_QUESTION_TYPE.DOCUMENT
              });
            }}
          />
        );
      default:
    }
  };

  const canSaveAsDraft =
    [
      SUBMISSION_FORM_STATES.EDITING_DRAFT,
      SUBMISSION_FORM_STATES.COMPLETING
    ].includes(submissionFormState) && hasCheckpointStarted;

  // <div className="w-full max-w-[560px]">
  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-center md:justify-between px-24 py-12">
        <div className="flex-grow text-center text-heading-sm font-semibold md:text-left">
          {t('submission')}
        </div>
        <div className="flex items-center gap-4">
          {canSaveAsDraft && (
            <>
              <div className="hidden md:block">
                <NPLButton
                  buttonText={t('save-as-draft')}
                  hierarchy="outline"
                  loading={isUpdatingSubmission}
                  size="sm"
                  onClick={handleSaveDraft}
                  disabled={isUploading || !participantId}
                />
              </div>
              <div className="md:hidden">
                <NPLButton
                  leadIcon="save-01"
                  hierarchy="plain"
                  loading={isUpdatingSubmission}
                  size="sm"
                  rounded
                  onClick={handleSaveDraft}
                  disabled={isUploading || !participantId}
                />
              </div>
            </>
          )}
          <div className="">
            <NPLButton
              size="sm"
              leadIcon="x-close"
              hierarchy="plain"
              onClick={closeSubmissionForm}
              rounded
              disabled={isUploading}
            />
          </div>
        </div>
      </div>
      <div className="flex max-w-[100dvw] flex-col gap-24 px-24 pt-24 pb-120">
        {submissionQuestions?.map((question, index) => {
          const { questionText, type } = question || {};
          return (
            <div key={questionText}>
              <div className="mb-6 text-label-md font-medium">
                {questionText}
              </div>
              {renderFormInputFromType(type, index)}
              {inputError[index] && (
                <div className="mt-6 text-label-md text-npl-error-light-11">
                  {inputError[index]}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CheckpointSubmissionForm;
