import { addDays, isSameYear } from 'date-fns';
import React from 'react';

import { getTimezoneId } from '@/utility/dateHelper';
import { formatDateTimeWithLocale, t } from '@/utility/localization';

import { getTimeZoneWithoutContinent } from '@/features/1on1Sessions/constants';

import Icon from '@/components/npl/Icon';

import UnlocksInCountdownText from '../../../UnlocksInCountdownText';

const CheckpointUnlocksInCountdown = ({
  isPreview,
  participantJoinedDate,
  checkpointData
}) => {
  const getCheckpointStartTime = (checkpoint) => {
    const { unlockAfterXDays } = checkpoint || {};
    let { startTime } = checkpoint || {};

    if (!startTime && !isPreview) {
      startTime = addDays(
        participantJoinedDate ?? new Date(),
        unlockAfterXDays ?? 0
      );
    }

    return startTime;
  };

  const startTime = getCheckpointStartTime(checkpointData);

  const formattedStartTime = formatDateTimeWithLocale(
    new Date(startTime),
    isSameYear(new Date(), new Date(startTime))
      ? 'dd MMM, hh:mm a'
      : 'dd MMM yyyy, hh:mm a'
  );

  const timezoneRegion = getTimeZoneWithoutContinent(getTimezoneId());

  // If start time is in the past, and its locked, means its a sequentual unlocking
  const isStartTimeInThePast = new Date(startTime) < new Date();

  const renderLockedComponent = () => {
    if (isStartTimeInThePast) {
      return (
        <p className="text-heading-sm font-semibold text-center">
          {t('complete-all-previous-checkpoints-to-unlock')}
        </p>
      );
    }
    return (
      <div className="flex flex-col items-center gap-8">
        <p className="text-heading-sm font-semibold flex gap-4 items-center">
          <span>{t('unlocks-in')}</span>
          <UnlocksInCountdownText unlockTime={startTime} />
        </p>
        <p className="text-label-md text-dark-1b text-opacity-60">
          {formattedStartTime}{' '}
          {t('timezoneRegion-time', {
            timezoneRegion
          })}
        </p>
      </div>
    );
  };

  return (
    <div className="w-full max-w-[560px] mx-auto">
      <div className="p-40 rounded-20 bg-npl-neutral-light-solid-2 flex flex-col items-center gap-16">
        <div className="w-80 h-80 flex items-center justify-center bg-white-default rounded-full">
          <Icon name="lock-03" width={40} height={40} fill="#1B1B1899" />
        </div>
        {renderLockedComponent()}
      </div>
    </div>
  );
};

export default CheckpointUnlocksInCountdown;
