import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
  useState
} from 'react';

const SideSheetContext = createContext<{
  handleClose: () => void;
  isClosed: boolean;
  onClose: () => void;
}>({
  handleClose: () => {},
  isClosed: false,
  onClose: () => {}
});

export const SideSheetProvider: FC<{
  children: ReactNode | ((onClose: () => void) => ReactNode);
  onClose: () => void;
  shouldBlockClose?: () => boolean;
}> = ({ children, onClose, shouldBlockClose = () => false }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    if (shouldBlockClose()) return;
    setIsClosed(true);
    // Only block scroll when sheet is actually open
    document.body.style.overflow = 'initial';
  };

  // This is useLayoutEffect to prevent layout shifting when side sheet is closed
  useLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <SideSheetContext.Provider
      value={{
        handleClose,
        isClosed,
        onClose
      }}>
      {typeof children === 'function' ? children(handleClose) : children}
    </SideSheetContext.Provider>
  );
};

export const useSideSheet = () => {
  const context = useContext(SideSheetContext);
  return context;
};
